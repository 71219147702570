import moment from "moment";

const converterStringToDate = (data: string | null) => {
	let date = moment().toDate();

	if (data !== null) {
		date = moment(data).toDate();
	}

	return date;
};

const converterHourToDate = (hour: string | null) => {
	let date = moment().toDate();

	if (hour !== null) {
		date = moment(`2023-09-29T${hour}`).toDate();
	}

	return date;
};

const formatDateString = (date: string | null, format = "DD/MM/YY") => {
	if (date !== null) {
		return moment(date).format(format);
	}
};

const formatHourString = (hour: string | null, format = "HH:mm") => {
	if (hour !== null) {
		return moment(`2023-09-11T${hour}`).format(format);
	}
};

const addDays = (days: number) => {
	const date = moment();

	return date.add(days, "days").toDate();
};

const getDateTime = (date: Date | null, format = "DD/MM/YYYY HH:mm:ss") => {
	if (date !== null) {
		return moment(date).format(format);
	}

	return "";
};

const getDiffMonth = (date: string) => {
	const dataAtual = moment();
	const dataEspecifica = moment(date);

	const diferenca = moment.duration(dataAtual.diff(dataEspecifica));

	const anos = diferenca.years();
	const meses = diferenca.months();
	const dias = diferenca.days();

	const mensagem = [];

	if (anos > 0) {
		mensagem.push(`${anos} ${anos === 1 ? "ano" : "anos"}`);
	}

	if (meses > 0) {
		if (anos > 0) {
			mensagem.push(` e ${meses} ${meses === 1 ? "mês" : "meses"}`);
		} else {
			mensagem.push(`${meses} ${meses === 1 ? "mês" : "meses"}`);
		}
	}

	if (anos > 0 || meses > 0) {
		if (anos > 0 && meses == 0) {
			mensagem.push(` e ${dias} ${dias <= 1 ? "dia" : "dias"}`);
		}
	} else {
		mensagem.push(`${dias} ${dias <= 1 ? "dia" : "dias"}`);
	}

	return mensagem;
};

const getNow = () => {
	return moment().toDate();
};

export const DataHelper = {
	converterStringToDate,
	converterHourToDate,
	formatDateString,
	formatHourString,
	addDays,
	getDateTime,
	getDiffMonth,
	getNow
};