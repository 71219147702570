import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppRouter from "./routes";
import {ThemeProvider} from "@material-tailwind/react";
import "tailwindcss/tailwind.css";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {ptBR} from "@mui/x-date-pickers/locales";
import ptBRDate from "date-fns/locale/pt-BR";
import "react-toastify/dist/ReactToastify.css";

const darkTheme = {
	component: {
		defaultProps: {
			// Estilos padrão para componentes em tema escuro
			padding: "2",
			backgroundColor: "bg-gray-800",
			textColor: "text-white",
		},
		valid: {
			// Estilos para estados válidos de componentes em tema escuro
			borderColor: "border-green-500",
		},
		styles: {
			// Estilos globais do tema escuro
			bodyBackground: "bg-gray-900",
		},
	},
};

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);
root.render(
	// <React.StrictMode>
	<ThemeProvider value={{theme: darkTheme}}>
		<LocalizationProvider dateAdapter={AdapterDateFns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={ptBRDate}>
			<AppRouter/>
		</LocalizationProvider>
	</ThemeProvider>
	// </React.StrictMode>
);
