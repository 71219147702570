import axios, {AxiosInstance} from "axios";

const defaultHeaders = {
	"Authorization": process.env.REACT_APP_AUTHORIZATION ?? "1krOmflwFqw2lab4RYr5603rAbaXz3f75bkn488U",
	"Content-Type": "application/json; charset=utf-8"
};

export const Api: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_API ?? "https://app-api.icrd.com.br/api",
	headers: defaultHeaders,
	maxContentLength: Infinity,
});
