import ERotas from "enums/ERotas";
import EStrings from "enums/EStrings";
import {BaseHelper} from "helpers/BaseHelper";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import {IListarTermoResult} from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import {useNavigate} from "react-router-dom";
import {TermosService} from "services/TermosService";
import {ToastHelper} from "helpers/ToastHelper";
import {ISolicitarReenvioFinalizacaoTermoCommand} from "interfaces/Commands/TermosCommands/ISolicitarReenvioFinalizacaoTermoCommand";
import {Card, CardBody, Dialog, DialogBody, DialogFooter, DialogHeader, IconButton, List, ListItem, ListItemSuffix, Typography} from "@material-tailwind/react";
import ImageComponent from "components/ImageComponent";
import DocumentoInvalido from "assets/modeloInvalido.jpg";
import DocumentoValido from "assets/modeloValido.jpg";
import InputDropDragPdfComponent from "components/InputDropDragComponents/PdfComponent";
import {HiOutlineTrash} from "react-icons/hi2";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import AlertaMotivoReprovacaoFinalizacaoTermoComponent from "../../cards/alertaMotivoReprovacaoFinalizacao";

interface IProps {
	termo?: IListarTermoResult;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

export default function ModalTermoSolicitarReenvioFinalizacao(props: IProps) {
	const {
		termo,
		open,
		setOpen,
		setAtualizarLista
	} = props;

	const navigate = useNavigate();
	const tokenVisao = localStorage.getItem("tokenLogado");

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const [files, setFiles] = useState<File[]>([]);

	const handleEnviarFormularioReenvioFinaliza = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const filesBase64: string[] = [];

			const base64String = await BaseHelper.FileToBase64(files[0]);

			filesBase64.push(base64String);

			const params: ISolicitarReenvioFinalizacaoTermoCommand = {
				token: termo?.token ?? "",
				files: filesBase64
			};

			const result = await TermosService.solicitarReenvioFinalizacao(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setOpen(false);
			setAtualizarLista(true);
			ToastHelper("success", "Termo reenviado para finalização com sucesso.");
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		} finally {
			setDesabilitarBotao(false);
		}
	};

	const handleExcluirFile = (indexToRemove: number) => {
		setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"xxl"} animate={BaseHelper.AnimationDialog} className={"h-[42rem] overflow-scroll"}>
			<DialogHeader>Solicitar a finalização do termo # {termo?.id}</DialogHeader>
			<DialogBody className={"space-y-3"}>
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					Anexe abaixo o termo assinado para finalização.
				</Typography>
				<AlertaMotivoReprovacaoFinalizacaoTermoComponent motivo_reprovacao={termo?.motivo_reprovacao_finalizacao}/>
				<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12 w-100"}>
					<div className={"sm:col-span-6 flex justify-center"}>
						<Card className={"w-full"} variant={"gradient"} color={"red"}>
							<CardBody className={"w-full m-0 p-2 space-y-2 text-center"}>
								<Typography variant={"h6"} className={"font-monospace"}>
									Não serão aceitos documentos
								</Typography>
								<ul className={"mt-2 ml-2 list-inside list-disc text-start p-5"}>
									<li>Com rasuras.</li>
									<li>Cortados ou tortos.</li>
									<li>Não legíveis ou desconfigurados.</li>
									<li>A marca da ICRD não deve ser mostrada com cores alternativas, bordas adicionais ou textos distorcidos.</li>
								</ul>

								<ImageComponent image={DocumentoInvalido}/>
							</CardBody>
						</Card>
					</div>

					<div className={"sm:col-span-6 flex justify-center"}>
						<Card className={"w-full"} variant={"gradient"} color={"green"}>
							<CardBody className={"w-full m-0 p-2 space-y-2 text-center"}>
								<Typography variant={"h6"} className={"font-monospace"}>
									Serão aceitos documentos
								</Typography>
								<ul className={"mt-2 ml-2 list-inside list-disc text-start p-5"}>
									<li>Documento deverá ser um arquivo único em PDF.</li>
									<li>Caso não possua scanner, sugerimos o uso do aplicativo Adobe Scan.</li>
									Obs.: O PDF deve ser somente do documento ou em fundo branco sem cortes e boa qualidade e com todas as informações legíveis.
								</ul>

								<ImageComponent image={DocumentoValido}/>
							</CardBody>
						</Card>
					</div>
				</div>
				&emsp;
				<form id={"formFinaliza"} onSubmit={handleEnviarFormularioReenvioFinaliza} className={"mt-3"} noValidate>
					<InputDropDragPdfComponent multiple={false} setFiles={setFiles}/>
				</form>
				<List>
					{files.map((file, index) => (
						<ListItem key={index} ripple={false} className={"py-1 pr-1 pl-4"}>
							{file.name} - {BaseHelper.GetSizeFile(file.size)}
							<ListItemSuffix>
								<IconButton variant="text" color="blue-gray" onClick={() => handleExcluirFile(index)}>
									<HiOutlineTrash className={"w-5 h-5"}/>
								</IconButton>
							</ListItemSuffix>
						</ListItem>
					))}
				</List>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefaultComponent color={"red"} description={"Fechar"} onClick={() => setOpen(false)} desabilitar={desabilitarBotao}/>
				<ButtonSubmitComponent form={"formFinaliza"} description={"Confirmar"} color={"green"} desabilitar={desabilitarBotao}/>
			</DialogFooter>
		</Dialog>
	);
}