import {Accordion, AccordionBody, AccordionHeader, Button, Typography} from "@material-tailwind/react";
import {DadosBancarios} from "interfaces/CommandsResults/DadosBancariosResults/IListarDadosBancariosAtivosResult";
import React, {useState} from "react";
import logo from "assets/pix.png";
import {QRCodeSVG} from "qrcode.react";
import {HiOutlineClipboardDocument} from "react-icons/hi2";
import {ToastHelper} from "helpers/ToastHelper";
import styles from "./Accordion.module.scss";
import {BaseHelper} from "helpers/BaseHelper";
import {IconComponent} from "../IconComponent";

interface IProps {
	lista: DadosBancarios[];
}

export function AccordionComponent(props: IProps) {
	const {lista} = props;
	const [open, setOpen] = useState<number>(0);
	const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

	const handleCopiarChave = (payload: string) => {
		const textarea = document.createElement("input");
		textarea.value = payload;

		// Define a posição absoluta e torna o elemento invisível
		textarea.style.position = "absolute";
		textarea.style.left = "-9999px";

		// Adiciona o elemento à página
		document.body.appendChild(textarea);

		// Seleciona o texto dentro do elemento
		textarea.select();

		// Executa o comando de cópia
		document.execCommand("copy");

		// Remove o elemento da página
		document.body.removeChild(textarea);

		ToastHelper("success", "Chave copiada com sucesso.");
	};

	return (
		<div className={styles.div}>
			{
				lista.map((item, index) => (
					<Accordion key={index} open={open === index} icon={IconComponent.arrow(index, open)} animate={BaseHelper.AnimationAccordion}>
						<AccordionHeader onClick={() => handleOpen(index)}>
							PIX - {item.descricao_tipo_chave}
						</AccordionHeader>
						<AccordionBody>
							<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
								<div className={"sm:col-span-6"}>
									<div className={"flex justify-center"}>
										<div>
											<Typography variant={"h4"}>Dados da Conta</Typography>
											<div className={"flex space-x-2"}>
												<Typography variant={"lead"}>Banco: </Typography>
												<Typography variant={"lead"} className={"font-light"}>{item.banco}</Typography>
											</div>

											<div className={"flex space-x-2"}>
												<Typography variant={"lead"}>Agência: </Typography>
												<Typography variant={"lead"} className={"font-light"}>{item.agencia}</Typography>
											</div>

											<div className={"flex space-x-2"}>
												<Typography variant={"lead"}>Conta: </Typography>
												<Typography variant={"lead"} className={"font-light"}>{item.conta}</Typography>
											</div>

											<div className={"flex space-x-2"}>
												<Typography variant={"lead"}>{item.tipo_cpf_cnpj === "PJ" ? "CNPJ" : "CPF"}: </Typography>
												<Typography variant={"lead"} className={"font-light"}>{item.cpf_cnpj}</Typography>
											</div>
										</div>
									</div>
								</div>
								<div className={"sm:col-span-6"}>
									<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
										<div className={"sm:col-span-6 flex justify-center"}>
											<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
												<div className={"sm:col-span-12"}>
													<img src={logo} alt={"Logo do Pix"} className={"w-50"}/>
												</div>
												<div className={"sm:col-span-12 flex justify-center"}>
													<Button
														className="flex items-center gap-3 rounded-full"
														color={"cyan"}
														variant={"gradient"}
														size={"sm"}
														onClick={() => handleCopiarChave(item.payload)}>
														<HiOutlineClipboardDocument className={"w-5 h-5"}/>
														Copiar Chave
													</Button>
												</div>
											</div>
										</div>
										<div className={"sm:col-span-6 flex justify-center"}>
											<QRCodeSVG value={item.payload} size={200}/>
										</div>
									</div>
								</div>
							</div>
						</AccordionBody>
					</Accordion>)
				)
			}
		</div>
	);
}