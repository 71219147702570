export const EStatusTermo = {
	PENDENTE: 1,
	INATIVO: 2,
	REPROVADO: 3,
	APROVADO: 4,
	CANCELADO: 5,
	EDICAO: 6,
	AGUARDANDO: 7,
	SOLICITACAO_FINALIZACAO: 8,
	FINALIZADO: 9,
	SOLICITACAO_CANCELAMENTO: 10,
	ROLLBACK: 11,
	REPROVADO_FINALIZACAO: 12,
	SOLICITACAO_REENVIO_FINALIZACAO: 13
};