import {Grid} from "@mui/material";
import {Card, Typography} from "@material-tailwind/react";
import {HiOutlineExclamation} from "react-icons/hi";
import React from "react";
import styles from "./alertaCriacao.module.scss";

export default function AlertaCriacaoRequerimentoComponent() {
	return (
		<Card className="w-full">
			<Grid container spacing={2} justifyContent={"center"}>
				<Grid item xs={2} justifyContent={"center"} alignItems={"center"}>
					<div className={`${styles.alerta} w-full h-full flex flex-col items-center justify-center`}>
						<Typography className={"font-medium mb-2"}>
							<HiOutlineExclamation/>
						</Typography>
						<Typography className="font-medium text-center">
							Fique atento ao preenchimento
						</Typography>
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className="card-body row text-start p-5 space-y-2">
						<ul className={"mt-2 ml-2 list-inside list-disc"}>
							<Typography className={"font-semibold"}><li>Coloque primeiro nome do noivo e depois o nome da noiva.</li></Typography>
							<Typography className={"font-semibold"}><li>No caso do homoafetivo coloque o nome em ordem alfabética.</li></Typography>
							<Typography className={"font-semibold"}><li>A Nacionalidade é brasileira letra minúscula, tanto para (noivo e noiva).</li></Typography>
							<Typography className={"font-semibold"}><li>Preencha corretamente para não ter o documento reprovado.</li></Typography>
						</ul>

						<Typography className={"font-semibold"}>Após criar o seu documento, acesse a pasta REQUERIMENTO PENDENTE, clique em VISUALIZE SEU MODELO.</Typography>
					</div>
				</Grid>
			</Grid>
		</Card>
	);
}