import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {IListarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IListarRequerimentoResult";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import SpinnerComponent from "components/SpinnerComponent";
import {Breadcrumbs} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import TabelaRequerimento from "pages/requerimento/_components/tabela";
import {ToastHelper} from "helpers/ToastHelper";
import {RequerimentosService} from "services/RequerimentosService";
import EAcesso from "enums/EAcesso";

export default function ListarRequerimentosRollback() {
	const navigate = useNavigate();
	const tokenVisao = localStorage.getItem("tokenLogado");
	const acesso = localStorage.getItem("acesso");

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(true);
	const [listaRequerimentos, setListaRequerimentos] = useState<IListarRequerimentoResult[]>([]);
	const [atualizarLista, setAtualizarLista] = useState<boolean>(false);

	useEffect(() => {
		if (acesso !== EAcesso.MASTER.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}

		listarRequerimentos();
	}, []);

	useEffect(() => {
		listarRequerimentos();
	}, [atualizarLista]);

	const listarRequerimentos = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			setIsLoadingIndex(false);
			return navigate(ERotas.LOGIN);
		}

		try {
			const result = await RequerimentosService.listarRollback(tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoadingIndex(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(ERotas.PRINCIPAL);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			setListaRequerimentos(body.data);
			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoadingIndex(false);
			return navigate(ERotas.PRINCIPAL);
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}
			<div className={"flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5"}>
				<Breadcrumbs>
					<a className={"opacity-60"}> Requerimentos </a>
					<a className={"opacity-60"}> Rollback </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>

			<TabelaRequerimento data={listaRequerimentos} setIsLoadingIndex={setIsLoadingIndex} setAtualizarLista={setAtualizarLista}/>
		</>
	);
}