const ERotas = {
	// ROTAS NÃO LOGADAS
	LOGIN: "/",
	ESQUECI_SENHA: "/esqueci-senha",
	PRIMEIRO_ACESSO: "/primeiro-acesso",
	// ROTAS LOGADAS
	PRINCIPAL: "/app",
	// CARTÓRIOS
	CARTORIOS_LISTAR: "/app/cartorios/listar",
	CARTORIOS_ADICIONAR: "/app/cartorios/adicionar",
	// DADOS BANCÁRIOS
	DADOS_BANCARIOS_LISTAR: "/app/dados-bancarios/listar",
	// REQUERIMENTOS
	REQUERIMENTO_LISTAR_TODOS: "/app/requerimentos/listar/todos",
	REQUERIMENTO_LISTAR_AGUARDANDO: "/app/requerimentos/listar/aguardando",
	REQUERIMENTO_LISTAR_APROVADOS: "/app/requerimentos/listar/aprovados",
	REQUERIMENTO_LISTAR_CANCELADOS: "/app/requerimentos/listar/cancelados",
	REQUERIMENTO_LISTAR_FINALIZADOS: "/app/requerimentos/listar/finalizados",
	REQUERIMENTO_LISTAR_PENDENTES: "/app/requerimentos/listar/pendentes",
	REQUERIMENTO_LISTAR_REPROVADOS: "/app/requerimentos/listar/reprovados",
	REQUERIMENTO_LISTAR_ROLLBACK: "/app/requerimentos/listar/rollback",
	REQUERIMENTO_LISTAR_SOLICITACAO_CANCELAMENTO: "/app/requerimentos/listar/solicitacao-cancelamento",
	REQUERIMENTO_ADICIONAR: "/app/requerimentos/adicionar",
	REQUERIMENTO_VISUALIZAR: "/app/requerimentos/visualizar",
	REQUERIMENTO_EDITAR: "/app/requerimentos/editar",
	REQUERIMENTO_SOLICITAR_EDICAO: "/app/requerimentos/solicitar-edicao",
	// TERMOS
	TERMO_LISTAR_TODOS: "/app/termos/listar/todos",
	TERMO_LISTAR_AGUARDANDO: "/app/termos/listar/aguardando",
	TERMO_LISTAR_APROVADOS: "/app/termos/listar/aprovados",
	TERMO_LISTAR_CANCELADOS: "/app/termos/listar/cancelados",
	TERMO_LISTAR_PENDENTES: "/app/termos/listar/pendentes",
	TERMO_LISTAR_REPROVADOS: "/app/termos/listar/reprovados",
	TERMO_LISTAR_REPROVADOS_FINALIZACAO: "/app/termos/listar/reprovados-finalizacao",
	TERMO_LISTAR_ROLLBACK: "/app/termos/listar/rollback",
	TERMO_LISTAR_SOLICITACAO_CANCELAMENTO: "/app/termos/listar/solicitacao-cancelamento",
	TERMO_LISTAR_SOLICITACAO_FINALIZACAO: "/app/termos/listar/solicitacao-finalizacao",
	TERMO_LISTAR_FINALIZADOS: "/app/termos/listar/finalizados",
	TERMO_ADICIONAR: "/app/termos/adicionar",
	TERMO_EDITAR: "/app/termos/editar",
	TERMO_EDITAR_PRONTO: "/app/termos/editar-pronto",
	TERMO_VISUALIZAR: "/app/termos/visualizar",
	TERMO_SOLICITAR_EDICAO: "/app/termos/solicitar-edicao",
	TERMO_SOLICITAR_REENVIO_FINALIZACAO: "/app/termos/solicitar-reenvio",
	// USUARIOS
	USUARIO_LISTAR: "/app/usuarios/listar",
	USUARIO_ADICIONAR: "/app/usuario/adicionar",
	USUARIO_EDITAR: "/app/usuario/editar",
	USUARIO_CRACHA: "/cracha"
};

export default ERotas;