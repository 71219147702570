const EStatusRequerimento = {
	PENDENTE: 1,
	INATIVO: 2,
	REPROVADO: 3,
	APROVADO: 4,
	CANCELADO: 5,
	EDICAO: 6,
	AGUARDANDO: 7,
	SOLICITACAO_CANCELAMENTO: 8,
	ROLLBACK: 9,
	FINALIZADO: 10
};

export default EStatusRequerimento;