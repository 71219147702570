import React, {ChangeEventHandler, FocusEventHandler} from "react";
import {Input} from "@material-tailwind/react";
import classNames from "classnames";
import Styles from "./InputComponent.module.scss";

interface IType {
	// inputRef: React.Ref<HTMLInputElement> | undefined;
	type: string;
	label: string;
	value?: string | undefined;
	maxLength?: number;
	validation?: boolean;
	uppercase?: boolean;
	lowercase?: boolean;
	required?: boolean;
	readonly?: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export default function InputComponent(props: IType) {
	const {
		// inputRef,
		type = "text",
		label,
		value,
		maxLength,
		// validation = true,
		uppercase = false,
		lowercase = false,
		required = false,
		readonly = false,
		onChange,
		onBlur
	} = props;

	return (
		<Input
			crossOrigin={undefined}
			type={type}
			label={label}
			value={value}
			color={"amber"}
			variant={"outlined"}
			size={"md"}
			maxLength={maxLength}
			// error={!validation}
			// success={validation}
			onChange={onChange}
			onBlur={onBlur}
			required={required}
			readOnly={readonly}
			className={classNames({
				["uppercase"]: uppercase,
				["lowercase"]: lowercase,
				[Styles.input]: true,
				[Styles.readonly]: readonly
			})}
		/>
	);
}
