import stylesTema from "styles/Tema.module.scss";
import MenuComponent from "components/MenuComponent";
import HeaderComponent from "components/HeaderComponent";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import SpinnerComponent from "components/SpinnerComponent";
import ToastContainerComponent from "components/ToastContainerComponent";
import EStrings from "enums/EStrings";
import {UsuariosService} from "services/UsuariosService";
import ERotas from "enums/ERotas";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import FooterComponent from "components/FooterComponent";
import {Dialog, DialogFooter, DialogBody, DialogHeader, Typography} from "@material-tailwind/react";
import {BaseHelper} from "helpers/BaseHelper";
import {HiOutlineInformationCircle} from "react-icons/hi2";
import {ParametrosService} from "services/ParametrosService";
import {ToastHelper} from "helpers/ToastHelper";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";

export default function DefaultLayout() {
	const navigate = useNavigate();

	const tokenVisao = localStorage.getItem("tokenLogado");
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [aceitoTermo, setAceitoTermo] = useState<boolean>(false);

	const [openTermoUso, setOpenTermoUso] = useState<boolean>(false);

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	useEffect(() => {
		obterDadosUsuario();
	}, [0]);

	useEffect(() => {
		if (aceitoTermo) {
			obterDadosUsuario();
		}
	}, [aceitoTermo]);

	const obterDadosUsuario = async () => {
		const token = localStorage.getItem("tokenLogado");

		if (token === "" || token === undefined || token === null) {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const result = await UsuariosService.buscarDados(token);

			if (!result) {
				LogErrorHelper.redirectToLogin("warning", EStrings.ERRO_RESULT);

				return navigate(ERotas.LOGIN);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				LogErrorHelper.redirectToLogin("warning", errors[0].message);

				return navigate(ERotas.LOGIN);
			}

			const body = result.data;

			if (body == null) {
				LogErrorHelper.redirectToLogin("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(ERotas.LOGIN);
			}

			const data = body.data;


			if (data == undefined) {
				LogErrorHelper.redirectToLogin("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(ERotas.LOGIN);
			}

			localStorage.setItem("nome", data.nome);
			localStorage.setItem("sobrenome", data.sobrenome);
			localStorage.setItem("token", data.token);
			localStorage.setItem("acesso", data.acesso.toString());
			localStorage.setItem("ceo", data.ceo.toString());
			localStorage.setItem("foto", data.foto);

			setAceitoTermo(data.aceito_termo ?? false);

			setOpenTermoUso(!data.aceito_termo ?? false);

			setIsLoading(false);
		} catch (error) {
			if (error instanceof Error) {
				LogErrorHelper.redirectToLogin("error", error.message);
			} else {
				LogErrorHelper.redirectToLogin("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(ERotas.LOGIN);
		}
	};

	const handleTermoUso = async () => {
		setIsLoading(true);

		try {
			const result = await ParametrosService.buscarTermosUso(tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoading(false);
					return;
				}

				ToastHelper("warning", errors[0].message);
				setIsLoading(false);
				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoading(false);
				return;
			}

			window.open(body.data.url, "_blank");
			setIsLoading(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setIsLoading(false);
				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setIsLoading(false);
			return;
		}
	};

	const handleConcordarTermoUso = async () => {
		setDesabilitarBotao(true);
		setIsLoading(true);

		try {
			const result = await UsuariosService.aceitarTermo(tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoading(false);
					setDesabilitarBotao(false);

					return;
				}

				ToastHelper("warning", errors[0].message);
				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}

			ToastHelper("success", "Usuário aceitou os termos de uso com sucesso.");
			setAceitoTermo(true);
			setDesabilitarBotao(false);
			setOpenTermoUso(false);

			return;
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);

				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}
		}
	};

	return (
		<main className={"container_principal"}>
			<HeaderComponent setOpenMenu={setOpenMenu}/>
			{isLoading &&
				<SpinnerComponent/>
			}

			{!aceitoTermo &&
				<Dialog open={openTermoUso} handler={() => console.log("quis fechar")} size={"xs"} animate={BaseHelper.AnimationDialog}>
					<DialogHeader className={"text-center"}>
						<HiOutlineInformationCircle className={"w-32 h-32 mr-auto ml-auto"} color={"#3fc3ee"}/>
					</DialogHeader>
					<DialogBody>
						<Typography variant={"paragraph"} className={"text-monospace"}>
							Ao concordar com os <span className={"text-amber-500 cursor-pointer"} onClick={() => handleTermoUso()}>Termos de Uso</span>, o usuário declara estar ciente e de acordo com as regras e políticas de privacidade do nosso site.
						</Typography>
					</DialogBody>
					<DialogFooter>
						<ButtonDefaultComponent variant={"gradient"} color={"green"} description={"Concordar"} desabilitar={desabilitarBotao} onClick={handleConcordarTermoUso}/>
					</DialogFooter>
				</Dialog>
			}

			{!isLoading && aceitoTermo &&
				<div className={stylesTema.container}>
					<MenuComponent setOpenMenu={setOpenMenu} turnOn={openMenu}/>
					<Outlet/>
					<ToastContainerComponent/>
				</div>
			}

			<FooterComponent setLoading={setIsLoading}/>
		</main>
	);
}