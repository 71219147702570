import {Api} from "providers/Api";
import {ApiResult} from "interfaces/ApiResult";
import {IBuscarAtivoResult} from "interfaces/CommandsResults/VersoesResults/IBuscarAtivoResult";

const controller = "/versoes";

const buscarAtivo = (token: string) => Api.post<ApiResult<IBuscarAtivoResult>>(`${controller}/buscarAtivo`, {}, {
	headers: {
		token: token
	}
});

export const VersoesService = {
	buscarAtivo
};