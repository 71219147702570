const EStrings = {
	CEP_INVALIDO: "CEP inválido.",
	COOKIE: "xR02#36bgD^r@GDkiAZ2",
	DOMAIN: "http://localhost:3000/",
	DOWNLOAD_SUCESSO: "Download realizado com sucesso.",
	EMAIL_OBRIGATORIO: "O E-mail deve ser fornecido.",
	ERRO_BUSCAR_DADOS: "Ocorreu um erro ao buscar os dados. Por favor, tente novamente mais tarde!",
	ERRO_NAO_MAPEADO: "Ocorreu um erro desconhecido/não mapeado. Por favor, tente novamente mais tarde!",
	ERRO_SOLICITAR_RECUPERAR_SENHA: "Ocorreu um erro ao enviar o token de recuperação de senha. Por favor, tente novamente mais tarde!",
	ERRO_RESULT: "Ocorreu um erro ao processar a sua solicitação.",
	SUCESSO_RECUPERAR_SENHA: "Senha atualizada com sucesso.",
	SUCESSO_SOLICITAR_RECUPERAR_SENHA: "E-mail enviado com sucesso. Por favor, verifique a sua caixa de entrada e siga as etapas de recuperação.",
	ERRO_RECUPERAR_SENHA: "Ocorreu um erro na recuperação de senha. Por favor, tente novamente mais tarde!",
	ERRO_PRIMEIRO_ACESSO: "Ocorreu um erro na verificação do primeiro acesso. Por favor, tente novamente mais tarde!",
	REQUERIMENTO_FALHA_LISTAR: "Ocorreu um erro ao listar o requerimento. Por favor, tente novamente mais tarde!",
	REQUERIMENTO_NAO_AUTENTICADO_ACAO: "O Requerimento não está autenticado para esta ação.",
	REQUERIMENTO_NAO_LOCALIZADO: "Requerimento não foi localizado.",
	SENHA_OBRIGATORIO: "A Senha deve ser fornecida.",
	TERMO_NAO_AUTENTICADO_ACAO: "O Termo não está autenticado para esta ação.",
	TIMEOUT: 2000,
	TOKEN_INVALIDO: "O Token é inválido.",
	TOKEN_OBRIGATORIO: "O Token deve ser fornecido.",
	USUARIO_NAO_LOCALIZADO: "Usuário não foi localizado.",
	USUARIO_NAO_AUTENTICADO: "O Usuário não está autenticado. Por favor, tente novamente mais tarde ou entre em contato com a administradora!",
	USUARIO_NAO_AUTENTICADO_ACAO: "O Usuário não está autenticado para esta ação.",
	USUARIO_LOGADO: "Usuário logado com sucesso."
};

export default EStrings;