import {Button} from "@material-tailwind/react";
import {MouseEventHandler} from "react";

type Color = "white"
	| "blue-gray"
	| "gray"
	| "brown"
	| "deep-orange"
	| "orange"
	| "amber"
	| "yellow"
	| "lime"
	| "light-green"
	| "green"
	| "teal"
	| "cyan"
	| "light-blue"
	| "blue"
	| "indigo"
	| "deep-purple"
	| "purple"
	| "pink"
	| "red";
type Variant = "gradient" | "text";

interface IButton {
	description: string;
	variant?: Variant;
	color?: Color;
	onClick: MouseEventHandler<HTMLButtonElement>;
	desabilitar?: boolean;
}

export default function ButtonDefaultComponent(props: IButton) {
	const {
		description = "",
		variant = "gradient",
		color = "amber",
		onClick,
		desabilitar = false
	} = props;

	return (
		<Button
			className="rounded-full"
			size="sm"
			variant={variant}
			color={color}
			onClick={onClick}
			disabled={desabilitar}
		>
			{description}
		</Button>
	);
}