import {Input} from "@material-tailwind/react";
import {ChangeEventHandler, Dispatch, FocusEventHandler, SetStateAction} from "react";
import {HiOutlineEye, HiOutlineEyeOff} from "react-icons/hi";

interface IType {
	label: string;
	value?: string | undefined;
	visible: boolean;
	setVisible: Dispatch<SetStateAction<boolean>>;
	maxLength?: number;
	required?: boolean;
	readonly?: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export default function PasswordComponent(props: IType) {
	const {
		label,
		value,
		visible,
		setVisible,
		maxLength,
		required = false,
		readonly = false,
		onChange,
		onBlur
	} = props;

	const handleChangeIcon = () => {
		setVisible(!visible);
	};

	return (
		<div className="w-full">
			<Input
				crossOrigin={undefined}
				type={visible ? "text" : "password"}
				label={label}
				value={value}
				color={"amber"}
				variant={"outlined"}
				size={"md"}
				maxLength={maxLength}
				onChange={onChange}
				onBlur={onBlur}
				required={required}
				readOnly={readonly}
				icon={visible ?
					<HiOutlineEyeOff onClick={handleChangeIcon} className={"cursor-pointer"}/> :
					<HiOutlineEye onClick={handleChangeIcon} className={"cursor-pointer"}/>
				}
			/>
		</div>
	);
}