const showPdf = (base64: string) => {
	const byteCharacters = atob(base64);

	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);

	// Cria um blob a partir do array de buffer
	const blob = new Blob([byteArray], {type: "application/pdf"});

	// Cria uma URL para o blob
	const fileUrl = URL.createObjectURL(blob);

	// Abre uma nova guia com o PDF
	window.open(fileUrl, "_blank");
};

const baixar = (base64: string, descricao: string) => {
	const pdfDataUri = `data:application/pdf;base64,${base64}`;

	const link = document.createElement("a");
	link.href = pdfDataUri;
	link.target = "_blank";

	link.download = descricao;

	link.click();
};

export const PdfHelper = {
	showPdf,
	baixar
};