import {useNavigate} from "react-router-dom";
import {Dispatch, SetStateAction} from "react";
import {IListarDocumentosTermoResult} from "interfaces/CommandsResults/DocumentosResults/IListarDocumentosTermoResult";
import {GridColDef} from "@mui/x-data-grid";
import EAcesso from "enums/EAcesso";
import {Button, Typography} from "@material-tailwind/react";
import {HiOutlineTrash} from "react-icons/hi2";
import TableComponent from "components/TableComponent";
import {ToastHelper} from "helpers/ToastHelper";
import {LogErrorHelper} from "../../../../../helpers/LogErrorHelper";
import EStrings from "../../../../../enums/EStrings";
import ERotas from "../../../../../enums/ERotas";
import {IDeletarDocumentoCommand} from "interfaces/Commands/DocumentosCommands/IDeletarDocumentoCommand";
import {DocumentosService} from "services/DocumentosService";
import { FileHelper } from "helpers/FileHelper";

interface IProps {
	data: IListarDocumentosTermoResult[];
	setData: Dispatch<SetStateAction<IListarDocumentosTermoResult[]>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	setAtualizarListaDocumentos: Dispatch<SetStateAction<boolean>>;
}

export default function TabelaDocumentos(props: IProps) {
	const navigate = useNavigate();
	const {data, setData, setIsLoading, setAtualizarListaDocumentos} = props;

	const acesso = localStorage.getItem("acesso");
	const tokenVisao = localStorage.getItem("tokenLogado");
	const token = localStorage.getItem("token");

	const handleRemoverDocumento = (id: number, token: string, temporario: boolean) => {
		setIsLoading(true);

		if (temporario) {
			let lista = [...data];

			lista = lista.filter((item) => item.id !== id);

			setData(lista);

			ToastHelper("success", "Documento deletado com sucesso!");
			setIsLoading(false);
		} else {
			deletarDocumento(token);
		}
	};

	const deletarDocumento = async (token: string) => {
		setIsLoading(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IDeletarDocumentoCommand = {
				token: token
			};

			const result = await DocumentosService.deletar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", "Ocorreu um erro ao deletar o documento. Tente novamente mais tarde!");

				return;
			}

			if (!body.success) {
				ToastHelper("warning", "Ocorreu um erro ao processar a sua solicitação. Tente novamente mais tarde!");

				return;
			}

			ToastHelper("success", "Documento deletado com sucesso!");

			setAtualizarListaDocumentos(true);
		} catch (error) {
			setIsLoading(true);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	const columns: GridColDef[] = [
		{field: "tipo", headerName: "Tipo de Documento", width: 150},
		{
			field: "url", headerName: "Link", flex: 100, renderCell: (params) => {
				return <>
					<a href={"#"} onClick={(e) => {
						e.preventDefault();
						FileHelper.download(params.row.base64, params.row.tipo);
					}}>{params.row.descricao}</a>
				</>;
			}
		}
	];

	if (acesso === EAcesso.CELEBRANTE.toString()) {
		columns.push(
			{
				field: "acoes", headerName: "Ações", headerAlign: "center", width: 150, align: "center", renderCell: (params) => {
					return (
						<>
							{token === params.row.token_solicitante &&
								<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"red"} onClick={() => handleRemoverDocumento(params.row.id, params.row.token, params.row.temporario)}>
									<HiOutlineTrash/>
									<Typography variant={"small"} className={"font-normal"}>
										Excluir
									</Typography>
								</Button>
							}
						</>
					);
				}
			}
		);
	}

	return (
		<TableComponent columns={columns} rows={data}/>
	);
}