import {Button} from "@material-tailwind/react";
import styles from "./ButtonSubmitComponent.module.scss";
import classNames from "classnames";

type Color = "red" | "blue" | "green" | "amber";

interface IButtonSubmit {
	form: string;
	desabilitar: boolean;
	fullWidth?: boolean;
	color?: Color;
	description: string;
}

export default function ButtonSubmitComponent(props: IButtonSubmit) {
	const {
		form = "form",
		desabilitar = false,
		fullWidth = false,
		color = "amber",
		description = ""
	} = props;

	return (
		<Button
			type={"submit"}
			form={form}
			ripple={true}
			variant={"gradient"}
			size={"sm"}
			color={color}
			className={classNames({
				["rounded-full"]: true,
				[styles.button]: true
			})}
			fullWidth={fullWidth}
			disabled={desabilitar}
		>
			{desabilitar &&
				"Aguardando..."
			}

			{!desabilitar &&
				description
			}
		</Button>
	);
}
