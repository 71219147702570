import React, {Dispatch, SetStateAction} from "react";
import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";
import {ITestemunhaTermoResult} from "interfaces/CommandsResults/TestemunhasResults/ITestemunhaTermoResult";
import {Accordion, AccordionBody, AccordionHeader, Typography} from "@material-tailwind/react";
import styles from "../../NovoEditarTermo.module.scss";
import {IconComponent} from "components/IconComponent";
import {BaseHelper} from "helpers/BaseHelper";
import InputComponent from "components/InputComponent";
import {DatePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {ToastHelper} from "helpers/ToastHelper";
import {CepHelper} from "helpers/CepHelper";
import EStrings from "enums/EStrings";
import {CpfHelper} from "../../../../../../../helpers/CpfHelper";
import {ETipoIdentidade} from "enums/ETipoIdentidade";

interface IProps {
	open: number;
	setOpen: Dispatch<SetStateAction<number>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	listaTestemunhas: ITestemunhaTermoResult[];
	setListaTestemunhas: Dispatch<SetStateAction<ITestemunhaTermoResult[]>>;
	listaEstadosCivis: IBuscarComboboxResult[];
	listaGeneros: IBuscarComboboxResult[];
	listaEstados: IBuscarComboboxResult[];
	listaTiposIdentidades: IBuscarComboboxResult[];
}

export default function AccordionTestemunha(props: IProps) {
	const {
		open,
		setOpen,
		setIsLoading,
		listaTestemunhas,
		setListaTestemunhas,
		listaEstadosCivis,
		listaGeneros,
		listaEstados,
		listaTiposIdentidades
	} = props;

	const handleOpenAccordionTestemunha = (value: number) => setOpen(open === value ? 0 : value);

	return (
		<>
			{
				listaTestemunhas.map((testemunha, index) => {
					const isObrigatorio = index < 2;

					const handleInputChange = (name: string, value: string | IBuscarComboboxResult | Date | null) => {
						const listaTemp = [...listaTestemunhas];
						listaTemp[index][name] = value as string | IBuscarComboboxResult | Date | null;
						setListaTestemunhas(listaTemp);
					};

					const handleCpfChange = (value: string) => {
						const formattedCpf = CpfHelper.formatarCpf(value);

						handleInputChange("cpf", formattedCpf);

						if (!CpfHelper.validarCpf(formattedCpf)) {
							ToastHelper("warning", "CPF Inválido.");
							handleInputChange("cpf", "");

							return;
						}
					};

					const handleProfissaoChange = (value: string) => {
						const profissao = value.trim().toUpperCase();

						const regex = /^[A-ZÀ-Úa-zà-ú\s]+$/;

						if (!profissao.match(regex)) {
							ToastHelper("warning", "Profissão contém caracteres especiais.");

							return false;
						}

						if (profissao.includes("AUTONOMO") || profissao.includes("AUTÔNOMO") || profissao.includes("AUTONOMA") || profissao.includes("AUTÔNOMA")) {
							ToastHelper("warning", "Profissão inválida.");

							return false;
						}

						return true;
					};

					const handleCepChange = async (value: string) => {
						setIsLoading(true);

						value = CepHelper.formatarCep(value);

						handleInputChange("cep", value);

						const result = await CepHelper.buscarCep(value);

						const setValuesEmpty = () => {
							handleInputChange("cep", "");
							handleInputChange("endereco", "");
							handleInputChange("bairro", "");
							handleInputChange("cidade", "");
							handleInputChange("uf", "");
							handleInputChange("uf_object", null);
						};

						if (result == null) {
							setValuesEmpty();
							setIsLoading(false);

							ToastHelper("warning", EStrings.CEP_INVALIDO);

							return;
						}

						setIsLoading(false);

						handleInputChange("endereco", result.logradouro);
						handleInputChange("bairro", result.bairro);
						handleInputChange("cidade", result.localidade);
						handleInputChange("uf", result.uf);
						handleInputChange("uf_object", listaEstados.find((option) => option.sigla === result.uf) ?? null);
					};

					const handleTipoIdentidadeChange = (value: IBuscarComboboxResult | null) => {
						handleInputChange("tipo_identidade_object", value);

						if (value?.descricao.includes("CIN")) {
							handleInputChange("cpf", "");
							handleInputChange("documento_identidade", "");
							handleInputChange("orgao_expedidor", "e.target.value");
						} else if (listaTestemunhas[index].tipo_identidade_object?.descricao.includes("CPF")) {
							handleInputChange("cin", "");
						}
					};

					const handleCniChange = (value: string) => {
						const formattedCpf = CpfHelper.formatarCpf(value);

						handleInputChange("cin", formattedCpf);

						if (!CpfHelper.validarCpf(formattedCpf)) {
							ToastHelper("warning", "CIN Inválido.");
							handleInputChange("cin", "");

							return;
						}
					};

					return (
						<Accordion key={index} open={open === index} className={styles.accordion} icon={IconComponent.arrow(index, open)} animate={BaseHelper.AnimationAccordion}>
							<AccordionHeader
								onClick={() => handleOpenAccordionTestemunha(index)}
								className={`border-b-0 transition-colors ${
									open === index ? "text-amber-500 hover:!text-amber-700" : ""
								}`}
							>
								Testemunha # {(index + 1).toString().padStart(2, "0")} - {isObrigatorio ? "Obrigatório" : "Opcional"}
							</AccordionHeader>
							<AccordionBody>
								<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
									<div className={"sm:col-span-9"}>
										<InputComponent
											type={"text"}
											label={"Nome Completo da Testemunha"}
											value={listaTestemunhas[index].nome}
											required={isObrigatorio}
											onChange={(e) => handleInputChange("nome", e.target.value)}
										/>
									</div>

									<div className={"sm:col-span-3"}>
										<DatePicker
											label="Data de Nascimento"
											format={"dd/MM/yyyy"}
											value={listaTestemunhas[index].data_nascimento_date}
											onChange={(e) => handleInputChange("data_nascimento_date", e)}
											yearsPerRow={3}
											disableFuture={true}
											slotProps={{
												textField: {
													fullWidth: true,
													size: "small",
													required: isObrigatorio ?? true
												}
											}}
										/>
									</div>

									<div className={"sm:col-span-4"}>
										<InputComponent
											type={"text"}
											label={"Nacionalidade"}
											value={listaTestemunhas[index].nacionalidade}
											required={isObrigatorio}
											onChange={(e) => handleInputChange("nacionalidade", e.target.value)}
										/>
									</div>

									<div className={"sm:col-span-4"}>
										<Autocomplete
											options={listaEstadosCivis}
											getOptionLabel={(option) => option.descricao}
											value={listaTestemunhas[index].estado_civil_object}
											size={"small"}
											onChange={(_, newValue) => handleInputChange("estado_civil_object", newValue)}
											renderInput={(params) => <TextField {...params} label="Estado Civil" required={isObrigatorio}/>}
										/>
									</div>

									<div className={"sm:col-span-4"}>
										<Autocomplete
											options={listaGeneros}
											getOptionLabel={(option) => option.descricao}
											value={listaTestemunhas[index].genero_object}
											size={"small"}
											onChange={(_, newValue) => handleInputChange("genero_object", newValue)}
											renderInput={(params) => <TextField {...params} label={"Gênero"} required={isObrigatorio}/>}
										/>
									</div>

									<div className={"sm:col-span-3"}>
										<Autocomplete
											options={listaTiposIdentidades}
											getOptionLabel={(option) => option.descricao}
											value={listaTestemunhas[index].tipo_identidade_object}
											size={"small"}
											onChange={(_, newValue) => handleTipoIdentidadeChange(newValue)}
											renderInput={(params) => <TextField {...params} label={"Tipo de Identidade"} required={isObrigatorio}/>}
										/>
									</div>

									{(listaTestemunhas[index].tipo_identidade_object?.sigla == ETipoIdentidade.CIN) &&
										<>
											<div className={"sm:col-span-9"}>
												<InputComponent
													type={"text"}
													label={"N° do CIN"}
													value={listaTestemunhas[index].cin ?? undefined}
													required={true}
													onChange={(e) => handleInputChange("cin", e.target.value)}
													onBlur={(e) => handleCniChange(e.target.value)}
												/>
											</div>
										</>
									}

									{(listaTestemunhas[index].tipo_identidade_object?.sigla == ETipoIdentidade.CPF_RG) &&
										<>
											<div className={"sm:col-span-3"}>
												<InputComponent
													type={"text"}
													label={"CPF"}
													value={listaTestemunhas[index].cpf ?? undefined}
													required={isObrigatorio}
													onChange={(e) => handleInputChange("cpf", e.target.value)}
													onBlur={(e) => handleCpfChange(e.target.value)}
												/>
											</div>

											<div className={"sm:col-span-3"}>
												<InputComponent
													type={"text"}
													label={"Documento de Identificação"}
													value={listaTestemunhas[index].documento_identificacao ?? undefined}
													required={isObrigatorio}
													onChange={(e) => handleInputChange("documento_identificacao", e.target.value)}
												/>
											</div>

											<div className={"sm:col-span-3"}>
												<InputComponent
													type={"text"}
													label={"Órgao Expedidor"}
													value={listaTestemunhas[index].orgao_expedidor}
													required={isObrigatorio}
													onChange={(e) => handleInputChange("orgao_expedidor", e.target.value)}
												/>
											</div>
										</>
									}

									<div className={"sm:col-span-12"}>
										<InputComponent
											type={"text"}
											label={"Profissão"}
											value={listaTestemunhas[index].profissao}
											required={isObrigatorio}
											onChange={(e) => handleInputChange("profissao", e.target.value)}
											onBlur={(e) => {
												if (!handleProfissaoChange(e.target.value.toUpperCase())) {
													handleInputChange("profissao", "");
												}
											}}
										/>
									</div>

									<div className={"sm:col-span-12"}>
										<hr/>
									</div>

									<div className={"sm:col-span-4"}>
										<InputComponent
											type={"text"}
											label={"CEP"}
											value={CepHelper.formatarCep(listaTestemunhas[index].cep)}
											maxLength={9}
											required={isObrigatorio}
											onChange={(e) => handleInputChange("cep", e.target.value)}
											onBlur={(e) => {
												handleCepChange(e.target.value);
											}}
										/>
									</div>

									<div className={"sm:col-span-6"}>
										<InputComponent
											type={"text"}
											label={"Endereço"}
											value={listaTestemunhas[index].endereco}
											required={isObrigatorio}
											onChange={(e) => handleInputChange("endereco", e.target.value)}
										/>
									</div>

									<div className={"sm:col-span-2"}>
										<InputComponent
											type={"text"}
											label={"Número"}
											value={listaTestemunhas[index].numero}
											required={isObrigatorio}
											onChange={(e) => handleInputChange("numero", e.target.value)}
										/>
									</div>

									<div className={"sm:col-span-3"}>
										<InputComponent
											type={"text"}
											label={"Complemento"}
											value={listaTestemunhas[index].complemento}
											onChange={(e) => handleInputChange("complemento", e.target.value)}
										/>
									</div>

									<div className={"sm:col-span-3"}>
										<InputComponent
											type={"text"}
											label={"Bairro"}
											value={listaTestemunhas[index].bairro}
											required={isObrigatorio}
											onChange={(e) => handleInputChange("bairro", e.target.value)}
										/>
									</div>

									<div className={"sm:col-span-3"}>
										<InputComponent
											type={"text"}
											label={"Cidade"}
											value={listaTestemunhas[index].cidade}
											required={isObrigatorio}
											onChange={(e) => handleInputChange("cidade", e.target.value)}
										/>
									</div>

									<div className={"sm:col-span-3"}>
										<Autocomplete
											options={listaEstados}
											getOptionLabel={(option) => option.descricao}
											value={listaTestemunhas[index].uf_object}
											size={"small"}
											onChange={(event, newValue) => handleInputChange("uf_object", newValue)}
											renderInput={(params) => <TextField {...params} label="Estado" required={isObrigatorio}/>}
										/>
									</div>

									<div className={"sm:col-span-12"}>
										<hr/>
									</div>

									<div className={"sm:col-span-12"}>
										<Typography color={"red"} variant={"paragraph"} className={"font-monospace"}>
											Caso haja a solicitação do Cartório:
										</Typography>
									</div>

									<div className={"sm:col-span-6"}>
										<InputComponent
											type={"text"}
											label={"Nome do Pai"}
											value={listaTestemunhas[index].nome_pai}
											onChange={(e) => handleInputChange("nome_pai", e.target.value)}
										/>
									</div>

									<div className={"sm:col-span-6"}>
										<InputComponent
											type={"text"}
											label={"Nome da Mãe"}
											value={listaTestemunhas[index].nome_mae}
											onChange={(e) => handleInputChange("nome_mae", e.target.value)}
										/>
									</div>
								</div>
							</AccordionBody>
						</Accordion>
					);
				})
			}
		</>
	);

	// return (
	// 	<>
	// 		{
	// 			listaTestemunhas.map((testemunha, index) => {

	// 			})
	// 		}
	// 	</>
	// );
}
