import {PhotoIcon} from "@heroicons/react/24/outline";
import {Dispatch, SetStateAction, SyntheticEvent, useState} from "react";
import {ToastHelper} from "helpers/ToastHelper";

type Accept = "image/png" | "image/*" | "application/pdf";

interface IProps {
	setBase64: Dispatch<SetStateAction<string>>;
	accept: Accept;
}

export default function InputDropDragImageComponent(props: IProps) {
	const {setBase64, accept} = props;

	const [dragging, setDragging] = useState<boolean>(false);

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDragging(true);
	};

	const handleDragLeave = () => {
		setDragging(false);
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.persist();
		setDragging(false);

		const file = e.dataTransfer.files[0];

		changeBase64(file);
	};

	const handleChangeBase64 = (event: SyntheticEvent) => {
		const inputElement = event.target;

		if (inputElement instanceof HTMLInputElement && inputElement.files) {
			const file = inputElement.files[0];

			changeBase64(file);
		}
	};

	const changeBase64 = (file: File) => {
		if (file) {
			const typeArray = file.type.split("/", 2);
			const typeAcceptArray = accept.split("/", 2);

			// SE ACEITAR UM TIPO GENÉRICO
			if (typeAcceptArray[typeAcceptArray.length - 1] === "*") {
				if (typeArray[0] !== typeAcceptArray[0]) {
					ToastHelper("warning", "Por favor, selecione um arquivo válido.");

					return;
				}
			} else {
				// SE ACEITAR UM TIPO ESPECÍFICO
				if (file.type !== accept) {
					ToastHelper("warning", "Por favor, selecione um arquivo válido.");

					return;
				}
			}

			if (file.size > 10 * 1024 * 1024) {
				ToastHelper("warning", "Por favor, selecione um arquivo que não exceda 5MB.");

				return;
			}

			const reader = new FileReader();

			reader.onload = () => {
				if (reader.result) {
					setBase64(reader.result as string);
				}
			};

			reader.readAsDataURL(file);
		}
	};

	return (
		<div
			className={`flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 ${dragging ? "bg-blue-100" : ""}`}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDragOver={handleDragOver}
			onDrop={handleDrop}>
			<div className="text-center">
				<PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true"/>
				<div className="mt-4 flex text-sm leading-6 text-gray-600">
					<label htmlFor="fotoPerfil" className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
						<span>Selecione o Arquivo</span>
						<input
							id="fotoPerfil"
							name="fotoPerfil"
							type="file"
							accept={accept}
							className="sr-only"
							onChange={handleChangeBase64}
						/>
					</label>
					<p className="pl-1">ou arraste e solte ele aqui!</p>
				</div>
				<p className="text-xs leading-5 text-gray-600">Imagem de até 5MB</p>
			</div>
		</div>
	);
}