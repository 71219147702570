import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ERotas from "enums/ERotas";
import Login from "pages/login";
import Inicio from "pages/inicio";
import DefaultLayoutNoLogin from "layout/DefaultLayoutNoLogin";
import EsqueciSenha from "pages/esqueciSenha";
import EditarUsuario from "pages/usuario/editar";
import DefaultLayout from "layout/DefaultLayout";
import ListarUsuarios from "pages/usuario/listar";
import AdicionarUsuario from "pages/usuario/adicionar";
import PrimeiroAcesso from "pages/usuario/primeiroAcesso";
import ListarCartorios from "pages/cartorios/listar";
import ListarDadosBancarios from "pages/dadosBancarios/listar";
import ListarRequerimentos from "pages/requerimento/listas/todos";
import EditarRequerimento from "pages/requerimento/editar";
import SolicitarEdicaoRequerimento from "pages/requerimento/solicitarEdicao";
import ListarRequerimentosAguardando from "pages/requerimento/listas/aguardando";
import ListarRequerimentosAprovados from "pages/requerimento/listas/aprovados";
import ListarRequerimentosCancelados from "pages/requerimento/listas/cancelados";
import ListarRequerimentosPendentes from "pages/requerimento/listas/pendentes";
import ListarRequerimentosReprovados from "pages/requerimento/listas/reprovados";
import ListarRequerimentosSolicitacaoCancelamento from "pages/requerimento/listas/solicitacaoCancelamento";
import AdicionarRequerimento from "pages/requerimento/adicionar";
import VisualizarRequerimento from "pages/requerimento/visualizar";
import ListarTermos from "pages/termo/listas/todos";
import ListarTermosAguardando from "pages/termo/listas/aguardando";
import ListarTermosAprovados from "pages/termo/listas/aprovados";
import ListarTermosCancelados from "pages/termo/listas/cancelados";
import ListarTermosFinalizados from "pages/termo/listas/finalizados";
import ListarTermosPendentes from "pages/termo/listas/pendentes";
import ListarTermosReprovados from "pages/termo/listas/reprovados";
import ListarTermosSolicitacaoCancelamento from "pages/termo/listas/solicitacaoCancelamento";
import SolicitarEdicaoTermo from "pages/termo/solicitarEdicao";
import EditarTermo from "pages/termo/editar";
import AdicionarTermo from "./pages/termo/adicionar";
import EditarTermoSemAssinatura from "pages/termo/editarPronto";
import VisualizarTermo from "./pages/termo/visualizar";
import NotFound from "layout/NotFoundLayout";
import ListarRequerimentosRollback from "pages/requerimento/listas/rollback";
import ListarTermosRollback from "./pages/termo/listas/rollback";
import CrachaUsuario from "pages/usuario/cracha";
import ListarTermosSolicitacaoFinalizacao from "pages/termo/listas/solicitacaoFinalizacao";
import ListarTermosReprovadosFinalizacao from "./pages/termo/listas/reprovadosFinalizacao";
import ListarRequerimentosFinalizados from "pages/requerimento/listas/finalizados";

export default function AppRouter() {
	return (
		<Router>
			<Routes>
				<Route path={"/"} element={<DefaultLayoutNoLogin/>}>
					<Route index element={<Login/>}/>
					<Route path={ERotas.ESQUECI_SENHA} element={<EsqueciSenha/>}/>
					<Route path={ERotas.PRIMEIRO_ACESSO} element={<PrimeiroAcesso/>}/>
				</Route>
				<Route element={<DefaultLayout/>}>
					<Route path={ERotas.PRINCIPAL} element={<Inicio/>}/>
					{/* CARTÓRIOS */}
					<Route path={ERotas.CARTORIOS_LISTAR} element={<ListarCartorios/>}/>

					{/* DADOS BANCÁRIOS */}
					<Route path={ERotas.DADOS_BANCARIOS_LISTAR} element={<ListarDadosBancarios/>}/>

					{/* REQUERIMENTOS */}
					<Route path={ERotas.REQUERIMENTO_LISTAR_TODOS} element={<ListarRequerimentos/>}/>
					<Route path={ERotas.REQUERIMENTO_LISTAR_AGUARDANDO} element={<ListarRequerimentosAguardando/>}/>
					<Route path={ERotas.REQUERIMENTO_LISTAR_APROVADOS} element={<ListarRequerimentosAprovados/>}/>
					<Route path={ERotas.REQUERIMENTO_LISTAR_CANCELADOS} element={<ListarRequerimentosCancelados/>}/>
					<Route path={ERotas.REQUERIMENTO_LISTAR_FINALIZADOS} element={<ListarRequerimentosFinalizados/>}/>
					<Route path={ERotas.REQUERIMENTO_LISTAR_PENDENTES} element={<ListarRequerimentosPendentes/>}/>
					<Route path={ERotas.REQUERIMENTO_LISTAR_REPROVADOS} element={<ListarRequerimentosReprovados/>}/>
					<Route path={ERotas.REQUERIMENTO_LISTAR_ROLLBACK} element={<ListarRequerimentosRollback/>}/>
					<Route path={ERotas.REQUERIMENTO_LISTAR_SOLICITACAO_CANCELAMENTO} element={<ListarRequerimentosSolicitacaoCancelamento/>}/>
					<Route path={`${ERotas.REQUERIMENTO_EDITAR}/:token`} element={<EditarRequerimento/>}/>
					<Route path={`${ERotas.REQUERIMENTO_SOLICITAR_EDICAO}/:token`} element={<SolicitarEdicaoRequerimento/>}/>
					<Route path={ERotas.REQUERIMENTO_ADICIONAR} element={<AdicionarRequerimento/>}/>
					<Route path={`${ERotas.REQUERIMENTO_VISUALIZAR}/:token`} element={<VisualizarRequerimento/>}/>

					{/* TERMOS */}
					<Route path={ERotas.TERMO_LISTAR_TODOS} element={<ListarTermos/>}/>
					<Route path={ERotas.TERMO_LISTAR_AGUARDANDO} element={<ListarTermosAguardando/>}/>
					<Route path={ERotas.TERMO_LISTAR_APROVADOS} element={<ListarTermosAprovados/>}/>
					<Route path={ERotas.TERMO_LISTAR_CANCELADOS} element={<ListarTermosCancelados/>}/>
					<Route path={ERotas.TERMO_LISTAR_FINALIZADOS} element={<ListarTermosFinalizados/>}/>
					<Route path={ERotas.TERMO_LISTAR_PENDENTES} element={<ListarTermosPendentes/>}/>
					<Route path={ERotas.TERMO_LISTAR_REPROVADOS} element={<ListarTermosReprovados/>}/>
					<Route path={ERotas.TERMO_LISTAR_REPROVADOS_FINALIZACAO} element={<ListarTermosReprovadosFinalizacao/>}/>
					<Route path={ERotas.TERMO_LISTAR_ROLLBACK} element={<ListarTermosRollback/>}/>
					<Route path={ERotas.TERMO_LISTAR_SOLICITACAO_CANCELAMENTO} element={<ListarTermosSolicitacaoCancelamento/>}/>
					<Route path={ERotas.TERMO_LISTAR_SOLICITACAO_FINALIZACAO} element={<ListarTermosSolicitacaoFinalizacao/>}/>
					<Route path={`${ERotas.TERMO_SOLICITAR_EDICAO}/:token`} element={<SolicitarEdicaoTermo/>}/>
					<Route path={`${ERotas.TERMO_EDITAR}/:token`} element={<EditarTermo/>}/>
					<Route path={`${ERotas.TERMO_ADICIONAR}`} element={<AdicionarTermo/>}/>
					<Route path={`${ERotas.TERMO_EDITAR_PRONTO}/:token`} element={<EditarTermoSemAssinatura/>}/>
					<Route path={`${ERotas.TERMO_VISUALIZAR}/:token`} element={<VisualizarTermo/>}/>

					{/* USUÁRIOS */}
					<Route path={ERotas.USUARIO_ADICIONAR} element={<AdicionarUsuario/>}/>
					<Route path={`${ERotas.USUARIO_EDITAR}/:token`} element={<EditarUsuario/>}/>
					<Route path={ERotas.USUARIO_LISTAR} element={<ListarUsuarios/>}/>
				</Route>

				<Route path={`${ERotas.USUARIO_CRACHA}/:token`} element={<CrachaUsuario/>}/>

				<Route path={"*"} element={<NotFound/>}/>
			</Routes>
		</Router>
	);
}
