import {useNavigate, useParams} from "react-router-dom";
import styles from "./Cracha.module.scss";
import {Card, CardBody, Textarea, Typography} from "@material-tailwind/react";
import ToastContainerComponent from "components/ToastContainerComponent";
import React, {useEffect, useState} from "react";
import SpinnerComponent from "components/SpinnerComponent";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {UsuariosService} from "services/UsuariosService";
import {IPerfilCommand} from "interfaces/Commands/UsuariosCommands/IPerfilCommand";
import LogoUser from "assets/user-img.png";
import {DataHelper} from "helpers/DataHelper";
import moment from "moment";
import {Grid} from "@mui/material";
import InputComponent from "../../../components/InputComponent";
import classNames from "classnames";
import Cabecalho from "assets/cabecalho.png";
import ModalImage from "react-modal-image";

export default function CrachaUsuario() {
	const navigate = useNavigate();
	const {token} = useParams();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [imagem, setImagem] = useState<string>(LogoUser);
	const [nome, setNome] = useState<string>("USUÁRIO");
	const [sobrenome, setSobrenome] = useState<string>("SOBRENOME");
	const [documentoIdentidade, setDocumentoIdentidade] = useState<string>("");
	const [orgaoExpedidor, setOrgaoExpedidor] = useState<string>("");
	const [cpf, setCpf] = useState<string>("");
	const [dataCelebrante, setDataCelebrante] = useState<string>(moment().format("YYYY-MM-DD"));
	const [situacaoCadastro, setSituacaoCadastro] = useState<boolean>(false);

	useEffect(() => {
		buscarPerfil();
	}, [0]);

	const buscarPerfil = async () => {
		setIsLoading(true);

		if (token === undefined) {
			ToastHelper("warning", EStrings.TOKEN_OBRIGATORIO);
			setTimeout(() => {
				return navigate(ERotas.LOGIN);
			}, EStrings.TIMEOUT);

			return;
		}

		try {
			const params: IPerfilCommand = {
				token: token
			};

			const result = await UsuariosService.perfil(params);

			if (!result) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setTimeout(() => {
					return navigate(ERotas.LOGIN);
				}, EStrings.TIMEOUT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);
				setIsLoading(false);
				setTimeout(() => {
					return navigate(ERotas.LOGIN);
				}, EStrings.TIMEOUT);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoading(false);
				setTimeout(() => {
					return navigate(ERotas.LOGIN);
				}, EStrings.TIMEOUT);

				return;
			}

			const data = body.data;

			setNome(data.nome);
			setSobrenome(data.sobrenome);
			setDocumentoIdentidade(data.documento_identidade);
			setOrgaoExpedidor(data.orgao_expedidor);
			setCpf(data.cpf);
			setDataCelebrante(data.celebrante_desde);
			setSituacaoCadastro(data.situacao_cadastro);

			if (data.imagem !== "") {
				setImagem(data.imagem);
			}

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setTimeout(() => {
				return navigate(ERotas.LOGIN);
			}, EStrings.TIMEOUT);

			return;
		}
	};

	return (
		<>
			{isLoading &&
				<SpinnerComponent/>
			}

			<section className={styles.container}>
				<Card className={classNames({
					["max-w-[35rem] overflow-hidden"]: true,
					[styles.container__card]: true
				})}>
					<img src={Cabecalho} alt={"Cabecalho"}/>
					<CardBody className={classNames({
						["text-center space-y-2"]: true,
						[styles.container__card__body]: true
					})}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<InputComponent
									type={"text"}
									label={"Nome do Celebrante"}
									readonly={true}
									value={`${nome} ${sobrenome}`}
									validation={false}
								/>
							</Grid>

							<Grid item xs={4}>
								<ModalImage small={imagem} large={imagem} alt={"Perfil"} hideZoom={false} className={styles.container__imagem__profile}/>
							</Grid>

							<Grid item xs={8}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<InputComponent
											type={"text"}
											label={"Doc. Identidade - Órgão Emissor/UF"}
											readonly={true}
											value={`${documentoIdentidade} - ${orgaoExpedidor}`}
										/>
									</Grid>

									<Grid item xs={12}>
										<InputComponent
											type={"text"}
											label={"CPF"}
											readonly={true}
											value={cpf}
										/>
									</Grid>

									<Grid item xs={12}>
										<Textarea
											label={"Obs.:"}
											variant={"outlined"}
											size={"md"}
											color={"amber"}
											value={`Cargo Pastor(a) Celebrante\nCredenciado(a) em ${DataHelper.formatDateString(dataCelebrante, "DD/MM/YYYY")}`}
											readOnly={true}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<InputComponent
									type={"text"}
									label={"Status de Registro na Instituição"}
									readonly={true}
									value={situacaoCadastro ? "ATIVO" : "INATIVO"}
								/>
							</Grid>
						</Grid>
					</CardBody>
				</Card>
				<Typography color="blue-gray" className="font-normal">
					&copy;&ensp;{DataHelper.getNow().getFullYear()} Escola de Celebrantes
				</Typography>
				<ToastContainerComponent/>
			</section>
		</>
	);
}