import React, {FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Alert, Card, CardBody, Dialog, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import {HiOutlineExclamation} from "react-icons/hi";
import InputDropDragPdfComponent from "components/InputDropDragComponents/PdfComponent";
import {IBuscarTermoSemAssinaturaResult} from "interfaces/CommandsResults/TermosResults/IBuscarTermoSemAssinaturaResult";
import TabelaDocumentos from "../../tabelas/listarDocumentos";
import {IListarDocumentosTermoResult} from "interfaces/CommandsResults/DocumentosResults/IListarDocumentosTermoResult";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {IListarDocumentosTermoCommand} from "interfaces/Commands/DocumentosCommands/IListarDocumentosTermoCommand";
import {DocumentosService} from "services/DocumentosService";
import {BaseHelper} from "helpers/BaseHelper";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import {ToastHelper} from "helpers/ToastHelper";
import {ISalvarAtualizarDocumentoTermoCommand} from "interfaces/Commands/DocumentosCommands/ISalvarAtualizarDocumentoTermoCommand";
import {ISalvarAtualizarTermoSemAssinaturaCommand} from "interfaces/Commands/TermosCommands/ISalvarAtualizarTermoSemAssinaturaCommand";
import {TermosService} from "services/TermosService";
import EAcesso from "enums/EAcesso";
import SpinnerComponent from "components/SpinnerComponent";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import {DataHelper} from "helpers/DataHelper";
import {IRequerimentosAprovadosSemTermoResult} from "interfaces/CommandsResults/RequerimentosResults/IRequerimentosAprovadosSemTermoResult";

interface IProps {
	data?: IBuscarTermoSemAssinaturaResult | undefined;
	requerimento?: IRequerimentosAprovadosSemTermoResult | null;
	textoCriarAtualizar: string;
	novo: boolean;
}

export function FormularioSemAssinatura(props: IProps) {
	const {
		data,
		requerimento,
		textoCriarAtualizar,
		novo
	} = props;

	const navigate = useNavigate();
	const tokenVisao = localStorage.getItem("tokenLogado");
	const tokenUsuario = localStorage.getItem("token");
	const acesso = localStorage.getItem("acesso");

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [listaDocumentos, setListaDocumentos] = useState<IListarDocumentosTermoResult[]>([]);
	const [atualizarListaDocumentos, setAtualizarListaDocumentos] = useState<boolean>(false);

	const [termoSemAssinatura, setTermoSemAssinatura] = useState<File[]>([]);
	const [habilitacaoCasamento, setHabilitacaoCasamento] = useState<File[]>([]);

	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	useEffect(() => {
		if (data !== null && data !== undefined) {
			// if (data.data_casamento !== null) {
			// 	setDataCasamento(new Date(data.data_casamento));
			// }
			//
			// if (data.hora_casamento !== null) {
			// 	setHoraCasamento(data.hora_casamento);
			// }

			listarDocumentos(data?.token);
		}
	}, [data]);

	useEffect(() => {
		if (termoSemAssinatura.length > 0) {
			const carregarLista = async () => {
				const lista = [...listaDocumentos];
				const documentoIndex = listaDocumentos.findIndex((documento) => documento.tipo === "TERMO");

				setIsLoading(true);
				const base64String = await BaseHelper.FileToBase64(termoSemAssinatura[0]);

				if (documentoIndex === -1) {
					const item: IListarDocumentosTermoResult = {
						id: lista.length + 1,
						token: "",
						tipo: "TERMO",
						descricao: BaseHelper.GetToken(),
						base64: base64String,
						token_solicitante: tokenUsuario ?? "",
						temporario: true
					};

					lista.push(item);
				} else {
					lista[documentoIndex] = {
						...lista[documentoIndex],
						base64: base64String,
						token: "",
						temporario: true
					};
				}

				setListaDocumentos(lista);
				setIsLoading(false);
			};

			carregarLista();
		}
	}, [termoSemAssinatura]);

	useEffect(() => {
		if (habilitacaoCasamento.length > 0) {
			const carregarLista = async () => {
				const lista = [...listaDocumentos];
				const documentoIndex = listaDocumentos.findIndex((documento) => documento.tipo === "HABILITACAO");

				setIsLoading(true);
				const base64String = await BaseHelper.FileToBase64(habilitacaoCasamento[0]);

				if (documentoIndex === -1) {
					// NOTE:: NOVO DOCUMENTO
					const item: IListarDocumentosTermoResult = {
						id: lista.length + 1,
						token: "",
						tipo: "HABILITACAO",
						descricao: BaseHelper.GetToken(),
						base64: base64String,
						token_solicitante: tokenUsuario ?? "",
						temporario: true
					};

					lista.push(item);
				} else {
					lista[documentoIndex] = {
						...lista[documentoIndex],
						base64: base64String,
						token: "",
						temporario: true
					};
				}

				setListaDocumentos(lista);
				setIsLoading(false);
			};

			carregarLista();
		}
	}, [habilitacaoCasamento]);

	useEffect(() => {
		if (atualizarListaDocumentos) {
			listarDocumentos(data?.token ?? "");
		}
	}, [atualizarListaDocumentos]);

	const LogToast = (tipo: string, mensagem: string) => {
		ToastHelper(tipo, mensagem);

		setDesabilitarBotao(false);
		setOpenDialog(false);
	};

	const listarDocumentos = async (termo: string) => {
		setIsLoading(true);
		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			setIsLoading(false);

			const params: IListarDocumentosTermoCommand = {
				token: termo
			};

			const result = await DocumentosService.listarDocumentosTermo(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setListaDocumentos(body.data);
		} catch (error) {
			setIsLoading(true);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const documentos = listaDocumentos.filter((item) => item.temporario);
			const documentosCommand: ISalvarAtualizarDocumentoTermoCommand[] = [];
			documentos.forEach(item => {

				const documento: ISalvarAtualizarDocumentoTermoCommand = {
					token: item.token,
					tipo: item.tipo,
					base64: item.base64
				};

				documentosCommand.push(documento);
			});

			const params: ISalvarAtualizarTermoSemAssinaturaCommand = {
				token: props.data?.token ?? "",
				token_requerimento: props.data?.token_requerimento ?? (requerimento?.token ?? ""),
				documentos: documentosCommand
			};

			const result = await TermosService.salvarAtualizarSemAssinatura(params, tokenVisao);

			if (!result) {
				return LogToast("warning", EStrings.ERRO_RESULT);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);

				setOpenDialog(false);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				return LogToast("warning", EStrings.ERRO_BUSCAR_DADOS);
			}

			const data = body.data;

			LogToast("success", `Termo ${params.token == "" ? "criado" : "atualizado"} com sucesso!`);

			setTimeout(() => {
				if (acesso === EAcesso.MASTER.toString()) {
					navigate(`${ERotas.TERMO_VISUALIZAR}/${data.token}`);
				} else if (acesso === EAcesso.CELEBRANTE.toString()) {
					navigate(`${ERotas.TERMO_EDITAR_PRONTO}/${data.token}`);
				} else {
					navigate(-1);
				}
			}, EStrings.TIMEOUT);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				return LogToast("error", error.message);
			}

			return LogToast("error", EStrings.ERRO_NAO_MAPEADO);
		}
	};

	return (
		<>
			{isLoading &&
				<SpinnerComponent/>
			}

			<form id={"formTermo"} onSubmit={enviarFormulario} className={"space-y-8"} noValidate>
				<Card className={"w-full"}>
					<CardBody className={"w-full m-0 p-0 space-y-2"}>
						<Alert color={"red"} className={"w-full space-x-2 text-center flex items-center justify-center"} variant={"gradient"} icon={<HiOutlineExclamation/>}>
							<Typography className="font-medium">
								Orientação Importante!
							</Typography>
						</Alert>
						<div className="card-body row text-center p-5 space-y-2">
							<Typography className={"font-semibold"}>É importante anexar o TERMO expedido pelo Cartório sem preenchimento a caneta</Typography>
							<Typography className={"font-monospace"}>Verifique se o seu documento está em PDF</Typography>
						</div>
					</CardBody>
				</Card>

				<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
					<div className={"sm:col-span-6"}>
						<DatePicker
							label="Data do Evento"
							format={"dd/MM/yyyy"}
							value={DataHelper.converterStringToDate((requerimento?.data_evento ?? data?.data_casamento) ?? null)}
							readOnly={true}
							slotProps={{
								textField: {
									fullWidth: true,
									size: "small"
								}
							}}
						/>
					</div>

					<div className={"sm:col-span-6"}>
						<TimePicker
							label="Hora do Evento"
							format={"HH:mm"}
							value={DataHelper.converterHourToDate((requerimento?.hora_evento ?? data?.hora_casamento) ?? null)}
							ampm={false}
							slotProps={{
								textField: {
									fullWidth: true,
									required: true,
									size: "small"
								}
							}}
						/>
					</div>
				</div>

				<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
					<div className={"sm:col-span-6 justify-center align-center space-y-2"}>
						<legend>Termo Cartório (S/Assinatura)</legend>
						<InputDropDragPdfComponent multiple={false} setFiles={setTermoSemAssinatura}/>
					</div>
					<div className={"sm:col-span-6 justify-center align-center space-y-2"}>
						<legend>Habilitação de Casamento</legend>
						<InputDropDragPdfComponent multiple={false} setFiles={setHabilitacaoCasamento}/>
					</div>

					<div className={"sm:col-span-12"}>
						<legend className={"pb-3"}>Documentos Anexados</legend>
					</div>
					<TabelaDocumentos data={listaDocumentos} setData={setListaDocumentos} setIsLoading={setIsLoading} setAtualizarListaDocumentos={setAtualizarListaDocumentos}/>
				</div>

				<div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-6 mb-1">
					<div className="sm:col-span-12 ml-auto mr-auto">
						<ButtonDefaultComponent description={textoCriarAtualizar} onClick={() => setOpenDialog(!openDialog)}/>
						<Dialog open={openDialog} handler={() => setOpenDialog(!openDialog)} size={"sm"} animate={BaseHelper.AnimationDialog}>
							<DialogHeader>Tem certeza que deseja {novo ? "criar" : "atualizar"} o termo?</DialogHeader>
							<DialogFooter className={"space-x-2"}>
								<ButtonDefaultComponent color={"red"} description={"Cancelar"} onClick={() => setOpenDialog(!openDialog)} desabilitar={desabilitarBotao}/>
								<ButtonSubmitComponent form={"formTermo"} color={"green"} description={"Confirmar"} desabilitar={desabilitarBotao}/>
							</DialogFooter>
						</Dialog>
					</div>
				</div>
			</form>
		</>
	);
}