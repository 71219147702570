import {Grid} from "@mui/material";
import {Card, Typography} from "@material-tailwind/react";
import {HiOutlineExclamation} from "react-icons/hi";
import React from "react";
import styles from "./alertaCriacao.module.scss";

export default function AlertaCriacaoTermoComponent() {
	return (
		<Card className="w-full">
			<Grid container spacing={2} justifyContent={"center"}>
				<Grid item xs={2} justifyContent={"center"} alignItems={"center"}>
					<div className={`${styles.alerta} w-full h-full flex flex-col items-center justify-center`}>
						<Typography className={"font-medium mb-2"}>
							<HiOutlineExclamation/>
						</Typography>
						<Typography className="font-medium text-center">
							Orientação Importante!
						</Typography>
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className="card-body row text-start p-5 space-y-2">
						<Typography className={"font-semibold"}>Siga o passo a passo.</Typography>
						<ul className={"mt-2 ml-2 list-inside list-disc"}>
							<Typography className={"font-semibold"}><li>Todos os arquivos devem ser em PDF.</li></Typography>
							<Typography className={"font-semibold"}><li>Verifique se a habilitação está legível e sem cortes, em caso negativo será reprovado.</li></Typography>
							<Typography className={"font-semibold"}><li>A Nacionalidade é brasileira letra minúscula, tanto para (homens e mulheres).<br/>&ensp;&ensp; Preencha corretamente para não ter o documento reprovado.</li></Typography>
							<Typography className={"font-semibold"}><li>Após criar o seu documento, acesse a pasta TERMO PENDENTE, clique em VISUALIZE SEU MODELO.</li></Typography>
						</ul>
					</div>
				</Grid>
			</Grid>
		</Card>
	);
}