import {Api} from "providers/Api";
import {ApiResult} from "interfaces/ApiResult";
import {IListarTermosCommand} from "interfaces/Commands/TermosCommands/IListarTermosCommand";
import {IValidarCriacaoTermoResult} from "interfaces/CommandsResults/TermosResults/IValidarCriacaoTermoResult";
import {IValidarSolicitacaoEdicaoTermoCommand} from "interfaces/Commands/TermosCommands/IValidarSolicitacaoEdicaoTermoCommand";
import {IValidarSolicitacaoEdicaoTermoResult} from "interfaces/CommandsResults/TermosResults/IValidarSolicitacaoEdicaoTermoResult";
import {ISalvarComprovanteEdicaoTermoCommand} from "interfaces/Commands/TermosCommands/ISalvarComprovanteEdicaoTermoCommand";
import {ISalvarComprovanteEdicaoTermoResult} from "interfaces/CommandsResults/TermosResults/ISalvarComprovanteEdicaoTermoResult";
import {IDownloadTermoComprovanteCommand} from "interfaces/Commands/TermosCommands/IDownloadTermoComprovanteCommand";
import {IDownloadTermoComprovanteResult} from "interfaces/CommandsResults/TermosResults/IDownloadTermoComprovanteResult";
import {IAprovarEdicaoTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarEdicaoTermoCommand";
import {IAprovarEdicaoTermoResult} from "interfaces/CommandsResults/TermosResults/IAprovarEdicaoTermoResult";
import {IReprovarEdicaoTermoCommand} from "interfaces/Commands/TermosCommands/IReprovarEdicaoTermoCommand";
import {IReprovarEdicaoTermoResult} from "interfaces/CommandsResults/TermosResults/IReprovarEdicaoTermoResult";
import {IReprovarCancelamentoTermoCommand} from "interfaces/Commands/TermosCommands/IReprovarCancelamentoTermoCommand";
import {IReprovarCancelamentoTermoResult} from "interfaces/CommandsResults/TermosResults/IReprovarCancelamentoTermoResult";
import {IAprovarCancelamentoTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarCancelamentoTermoCommand";
import {IAprovarCancelamentoTermoResult} from "interfaces/CommandsResults/TermosResults/IAprovarCancelamentoTermoResult";
import {ISolicitarCancelamentoTermoResult} from "interfaces/CommandsResults/TermosResults/ISolicitarCancelamentoTermoResult";
import {ISolicitarCancelamentoTermoCommand} from "interfaces/Commands/TermosCommands/ISolicitarCancelamentoTermoCommand";
import {IDownloadTermoAprovadoCommand} from "interfaces/Commands/TermosCommands/IDownloadTermoAprovadoCommand";
import {IDownloadTermoFinalizadoCommand} from "interfaces/Commands/TermosCommands/IDownloadTermoFinalizadoCommand";
import {IDownloadTermoAprovadoResult} from "interfaces/CommandsResults/TermosResults/IDownloadTermoAprovadoResult";
import {IDownloadTermoFinalizadoResult} from "interfaces/CommandsResults/TermosResults/IDownloadTermoFinalizadoResult";
import {IBuscarTermoCommand} from "interfaces/Commands/TermosCommands/IBuscarTermoCommand";
import {IBuscarTermoResult} from "interfaces/CommandsResults/TermosResults/IBuscarTermoResult";
import {IBuscarModeloPdfTermoCommand} from "interfaces/Commands/TermosCommands/IBuscarModeloPdfTermoCommand";
import {IBuscarModeloPdfTermoResult} from "interfaces/CommandsResults/TermosResults/IBuscarModeloPdfTermoResult";
import {ISalvarAtualizarTermoCommand} from "interfaces/Commands/TermosCommands/ISalvarAtualizarTermoCommand";
import {ISalvarAtualizarTermoResult} from "interfaces/CommandsResults/TermosResults/ISalvarAtualizarTermoResult";
import {ISalvarAtualizarTermoSemAssinaturaCommand} from "interfaces/Commands/TermosCommands/ISalvarAtualizarTermoSemAssinaturaCommand";
import {ISalvarAtualizarTermoSemAssinaturaResult} from "interfaces/CommandsResults/TermosResults/ISalvarAtualizarTermoSemAssinaturaResult";
import {IBuscarTermoSemAssinaturaResult} from "interfaces/CommandsResults/TermosResults/IBuscarTermoSemAssinaturaResult";
import {IBuscarTermoSemAssinaturaCommand} from "interfaces/Commands/TermosCommands/IBuscarTermoSemAssinaturaCommand";
import {IBuscarPdfTermoCommand} from "interfaces/Commands/TermosCommands/IBuscarPdfTermoCommand";
import {IBuscarPdfTermoResult} from "../interfaces/CommandsResults/TermosResults/IBuscarPdfTermoResult";
import {IAprovarTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarTermoCommand";
import {IAprovarTermoResult} from "interfaces/CommandsResults/TermosResults/IAprovarTermoResult";
import {IReprovarTermoCommand} from "interfaces/Commands/TermosCommands/IReprovarTermoCommand";
import {IReprovarTermoResult} from "interfaces/Commands/TermosCommands/IReprovarTermoResult";
import {ISolicitarFinalizacaoTermoCommand} from "interfaces/Commands/TermosCommands/ISolicitarFinalizacaoTermoCommand";
import {ISolicitarFinalizacaoTermoResult} from "interfaces/CommandsResults/TermosResults/ISolicitarFinalizacaoTermoResult";
import {IListarTermoResult} from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import {IReprovarFinalizacaoTermoCommand} from "interfaces/Commands/TermosCommands/IReprovarFinalizacaoTermoCommand";
import {IReprovarFinalizacaoTermoResult} from "interfaces/CommandsResults/TermosResults/IReprovarFinalizacaoTermoResult";
import {IAprovarFinalizacaoTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarFinalizacaoTermoCommand";
import {IAprovarFinalizacaoTermoResult} from "interfaces/CommandsResults/TermosResults/IAprovarFinalizacaoTermoResult";
import {IDownloadTermoAprovacaoCommand} from "interfaces/Commands/TermosCommands/IDownloadTermoAprovacaoCommand";
import {IDownloadTermoAprovacaoResult} from "interfaces/CommandsResults/TermosResults/IDownloadTermoAprovacaoResult";
import {IAprovarCancelamentoParcialTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarCancelamentoParcialTermoCommand";
import {IAprovarCancelamentoParcialResult} from "interfaces/CommandsResults/TermosResults/IAprovarCancelamentoParcialResult";
import {IListarTimelineTermoCommand} from "interfaces/Commands/TermosCommands/IListarTimelineTermoCommand";
import {IListarTimelineTermoResult} from "../interfaces/CommandsResults/TermosResults/IListarTimelineTermoResult";
import {IAprovarRollbackTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarRollbackTermoCommand";
import {IAprovarRollbackTermoResult} from "../interfaces/CommandsResults/TermosResults/IAprovarRollbackTermoResult";
import {ISolicitarReenvioFinalizacaoTermoCommand} from "interfaces/Commands/TermosCommands/ISolicitarReenvioFinalizacaoTermoCommand";
import { ISolicitarReenvioFinalizacaoTermoResult } from "interfaces/CommandsResults/TermosResults/ISolicitarReenvioFinalizacaoTermoResult";

const controller = "/termos";

const validarCriacao = (token: string) => Api.post<ApiResult<IValidarCriacaoTermoResult>>(`${controller}/validarCriacao`, {}, {
	headers: {
		token: token
	}
});
const listar = (data: IListarTermosCommand, token: string) => Api.post<ApiResult<IListarTermoResult[]>>(`${controller}/listar`, data, {
	headers: {
		token: token
	}
});
const validarSolicitacaoEdicao = (data: IValidarSolicitacaoEdicaoTermoCommand, token: string) => Api.post<ApiResult<IValidarSolicitacaoEdicaoTermoResult>>(`${controller}/validarEdicao`, data, {
	headers: {
		token: token
	}
});
const salvarComprovanteEdicao = (data: ISalvarComprovanteEdicaoTermoCommand, token: string) => Api.post<ApiResult<ISalvarComprovanteEdicaoTermoResult>>(`${controller}/salvarComprovanteEdicao`, data, {
	headers: {
		token: token
	}
});
const downloadComprovante = (data: IDownloadTermoComprovanteCommand, token: string) => Api.post<ApiResult<IDownloadTermoComprovanteResult>>(`${controller}/downloadComprovante`, data, {
	headers: {
		token: token
	}
});
const aprovarEdicao = (data: IAprovarEdicaoTermoCommand, token: string) => Api.post<ApiResult<IAprovarEdicaoTermoResult>>(`${controller}/aprovarEdicao`, data, {
	headers: {
		token: token
	}
});
const reprovarEdicao = (data: IReprovarEdicaoTermoCommand, token: string) => Api.post<ApiResult<IReprovarEdicaoTermoResult>>(`${controller}/reprovarEdicao`, data, {
	headers: {
		token: token
	}
});
const solicitarCancelamento = (data: ISolicitarCancelamentoTermoCommand, token: string) => Api.post<ApiResult<ISolicitarCancelamentoTermoResult>>(`${controller}/solicitarCancelamento`, data, {
	headers: {
		token: token
	}
});
const reprovarCancelamento = (data: IReprovarCancelamentoTermoCommand, token: string) => Api.post<ApiResult<IReprovarCancelamentoTermoResult>>(`${controller}/reprovarCancelamento`, data, {
	headers: {
		token: token
	}
});
const aprovarCancelamento = (data: IAprovarCancelamentoTermoCommand, token: string) => Api.post<ApiResult<IAprovarCancelamentoTermoResult>>(`${controller}/aprovarCancelamento`, data, {
	headers: {
		token: token
	}
});
const downloadAprovado = (data: IDownloadTermoAprovadoCommand, token: string) => Api.post<ApiResult<IDownloadTermoAprovadoResult>>(`${controller}/downloadAprovado`, data, {
	headers: {
		token: token
	}
});
const downloadFinalizado = (data: IDownloadTermoFinalizadoCommand, token: string) => Api.post<ApiResult<IDownloadTermoFinalizadoResult>>(`${controller}/downloadFinalizado`, data, {
	headers: {
		token: token
	}
});
const buscar = (data: IBuscarTermoCommand, token: string) => Api.post<ApiResult<IBuscarTermoResult>>(`${controller}/buscar`, data, {
	headers: {
		token: token
	}
});
const buscarModeloPdf = (data: IBuscarModeloPdfTermoCommand, token: string) => Api.post<ApiResult<IBuscarModeloPdfTermoResult>>(`${controller}/buscarModelo`, data, {
	headers: {
		token: token
	}
});
const salvarAtualizar = (data: ISalvarAtualizarTermoCommand, token: string) => Api.post<ApiResult<ISalvarAtualizarTermoResult>>(`${controller}/salvarAtualizar`, data, {
	headers: {
		token: token
	}
});
const salvarAtualizarSemAssinatura = (data: ISalvarAtualizarTermoSemAssinaturaCommand, token: string) => Api.post<ApiResult<ISalvarAtualizarTermoSemAssinaturaResult>>(`${controller}/salvarAtualizarSemAssinatura`, data, {
	headers: {
		token: token
	}
});
const buscarSemAssinatura = (data: IBuscarTermoSemAssinaturaCommand, token: string) => Api.post<ApiResult<IBuscarTermoSemAssinaturaResult>>(`${controller}/buscarSemAssinatura`, data, {
	headers: {
		token: token
	}
});
const buscarPdf = (data: IBuscarPdfTermoCommand, token: string) => Api.post<ApiResult<IBuscarPdfTermoResult>>(`${controller}/buscarPdf`, data, {
	headers: {
		token: token
	}
});
const aprovar = (data: IAprovarTermoCommand, token: string) => Api.post<ApiResult<IAprovarTermoResult>>(`${controller}/aprovar`, data, {
	headers: {
		token: token
	}
});
const reprovar = (data: IReprovarTermoCommand, token: string) => Api.post<ApiResult<IReprovarTermoResult>>(`${controller}/reprovar`, data, {
	headers: {
		token: token
	}
});
const solicitarFinalizacao = (data: ISolicitarFinalizacaoTermoCommand, token: string) => Api.post<ApiResult<ISolicitarFinalizacaoTermoResult>>(`${controller}/solicitarFinalizacao`, data, {
	headers: {
		token: token
	}
});
const reprovarFinalizacao = (data: IReprovarFinalizacaoTermoCommand, token: string) => Api.post<ApiResult<IReprovarFinalizacaoTermoResult>>(`${controller}/reprovarFinalizacao`, data, {
	headers: {
		token: token
	}
});
const aprovarFinalizacao = (data: IAprovarFinalizacaoTermoCommand, token: string) => Api.post<ApiResult<IAprovarFinalizacaoTermoResult>>(`${controller}/aprovarFinalizacao`, data, {
	headers: {
		token: token
	}
});
const downloadAprovacao = (data: IDownloadTermoAprovacaoCommand, token: string) => Api.post<ApiResult<IDownloadTermoAprovacaoResult>>(`${controller}/downloadAprovacao`, data, {
	headers: {
		token: token
	}
});
const aprovarCancelamentoParcial = (data: IAprovarCancelamentoParcialTermoCommand, token: string) => Api.post<ApiResult<IAprovarCancelamentoParcialResult>>(`${controller}/aprovarCancelamentoParcial`, data, {
	headers: {
		token: token
	}
});
const listarTimeline = (data: IListarTimelineTermoCommand, token: string) => Api.post<ApiResult<IListarTimelineTermoResult>>(`${controller}/listarTimeline`, data, {
	headers: {
		token: token
	}
});
const listarRollback = (token: string) => Api.post<ApiResult<IListarTermoResult[]>>(`${controller}/listarRollback`, {}, {
	headers: {
		token: token
	}
});
const aprovarRollback = (data: IAprovarRollbackTermoCommand, token: string) => Api.post<ApiResult<IAprovarRollbackTermoResult>>(`${controller}/aprovarRollback`, data, {
	headers: {
		token: token
	}
});
const solicitarReenvioFinalizacao = (data: ISolicitarReenvioFinalizacaoTermoCommand, token: string) => Api.post<ApiResult<ISolicitarReenvioFinalizacaoTermoResult>>(`${controller}/solicitarReenvioFinalizacao`, data, {
	headers: {
		token: token
	}
});

export const TermosService = {
	validarCriacao,
	listar,
	validarSolicitacaoEdicao,
	salvarComprovanteEdicao,
	downloadComprovante,
	aprovarEdicao,
	reprovarEdicao,
	solicitarCancelamento,
	reprovarCancelamento,
	aprovarCancelamento,
	downloadAprovado,
	downloadFinalizado,
	buscar,
	buscarModeloPdf,
	salvarAtualizar,
	salvarAtualizarSemAssinatura,
	buscarSemAssinatura,
	buscarPdf,
	aprovar,
	reprovar,
	solicitarFinalizacao,
	reprovarFinalizacao,
	aprovarFinalizacao,
	downloadAprovacao,
	listarRollback,
	aprovarRollback,
	aprovarCancelamentoParcial,
	listarTimeline,
	solicitarReenvioFinalizacao
};