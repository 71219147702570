import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {IListarCartoriosResult} from "interfaces/CommandsResults/ParametrosResults/IListarCartoriosResult";
import {ToastHelper} from "helpers/ToastHelper";
import ERotas from "enums/ERotas";
import EStrings from "enums/EStrings";
import {ParametrosService} from "services/ParametrosService";
import {GridColDef} from "@mui/x-data-grid";
import {Breadcrumbs, Button, Card, CardBody} from "@material-tailwind/react";
import SpinnerComponent from "components/SpinnerComponent";
import TableComponent from "components/TableComponent";
import {DataHelper} from "helpers/DataHelper";
import {HiOutlineMinus, HiOutlinePencilSquare, HiOutlinePlus} from "react-icons/hi2";
import {FormularioCartorioComponent} from "../_components/formulario";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EAcesso from "enums/EAcesso";

export default function Listar() {
	const navigate = useNavigate();
	const tokenVisao = localStorage.getItem("tokenLogado");
	const acesso = localStorage.getItem("acesso");

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(true);
	const [listaCartorios, setListaCartorios] = useState<IListarCartoriosResult[]>([]);
	const [cartorio, setCartorio] = useState<IListarCartoriosResult | null>(null);
	const [tituloModal, setTituloModal] = useState<string>("");

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [atualizarLista, setAtualizarLista] = useState<boolean>(false);

	useEffect(() => {
		listarCartorios();
	}, []);

	useEffect(() => {
		listarCartorios();
	}, [atualizarLista]);

	useEffect(() => {
		if (acesso != EAcesso.MASTER.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(ERotas.PRINCIPAL);
		}
	}, [acesso]);

	const listarCartorios = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao == "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const result = await ParametrosService.listarCartorios(tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoadingIndex(false);

				return navigate(ERotas.PRINCIPAL);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoadingIndex(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(ERotas.PRINCIPAL);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			setListaCartorios(body.data);
			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoadingIndex(false);
			return navigate(ERotas.PRINCIPAL);
		}
	};

	const ativarDesativar = async (token: string) => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao == "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			setIsLoadingIndex(false);
			return navigate(ERotas.LOGIN);
		}

		try {
			const result = await ParametrosService.ativarDesativarCartorio({token}, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoadingIndex(false);

				return navigate(ERotas.PRINCIPAL);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoadingIndex(false);
					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(ERotas.CARTORIOS_LISTAR);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoadingIndex(false);

				return navigate(ERotas.CARTORIOS_LISTAR);
			}

			setIsLoadingIndex(false);
			ToastHelper("success", "Cartório atualizado com sucesso.");

			await listarCartorios();
		} catch (error) {
			setIsLoadingIndex(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(ERotas.PRINCIPAL);
		}
	};

	const handleAbrirModal = (data: IListarCartoriosResult | null, titulo: string) => {
		setCartorio(data);
		setTituloModal(titulo);
		setIsOpenModal(!isOpenModal);
	};

	const columns: GridColDef[] = [
		{field: "id", headerName: "ID", headerAlign: "center", width: 70, align: "center",},
		{field: "descricao", headerName: "Nome do Cartório", flex: 100},
		{
			field: "data_criacao", headerName: "Data de Criação", width: 150, valueGetter: (params) => {
				return DataHelper.formatDateString(params.value, "DD/MM/YY H:mm");
			}
		},
		{
			field: "status", headerName: "Status", headerAlign: "center", width: 100, align: "center", valueGetter: (params) => {
				return params.value ? "ATIVO" : "INATIVO";
			}
		},
		{
			field: "acoes", headerName: "Ações", headerAlign: "center", width: 100, align: "center", renderCell: (params) => {
				const isInative = params.row.status;

				const handleAbrir = () => {
					handleAbrirModal(params.row, "Editar Cartório");
				};

				const handleAtivarCartorio = async () => {
					const token = params.row.token;

					await ativarDesativar(token);
				};

				return (
					<>
						<Button
							className={"rounded-full"}
							size={"sm"}
							variant={"gradient"}
							color={"amber"}
							onClick={() => handleAbrir()}
						>
							<HiOutlinePencilSquare/>
						</Button>
						&ensp;
						{isInative &&
							<Button
								className={"rounded-full"}
								size={"sm"}
								variant={"gradient"}
								color={"red"}
								onClick={handleAtivarCartorio}
							><HiOutlineMinus/></Button>
						}

						{!isInative &&
							<Button
								className={"rounded-full"}
								size={"sm"}
								variant={"gradient"}
								color={"green"}
								onClick={handleAtivarCartorio}
							><HiOutlinePlus/></Button>
						}
					</>
				);
			}
		}
	];

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a className="opacity-60"> Cartórios </a>
					<a className="opacity-60"> Listar </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<div>
						<ButtonDefaultComponent description={"Adicionar"} onClick={() => handleAbrirModal(null, "Adicionar Cartório")}/>
					</div>
					<TableComponent columns={columns} rows={listaCartorios}/>
					<FormularioCartorioComponent open={isOpenModal} setOpen={setIsOpenModal} titulo={tituloModal} data={cartorio} setAtualizarLista={setAtualizarLista}/>
				</CardBody>
			</Card>
		</>
	);
}