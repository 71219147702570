import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import {HiOutlineDownload} from "react-icons/hi";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import ERotas from "enums/ERotas";
import React, {Dispatch, SetStateAction, useState} from "react";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import {ToastHelper} from "helpers/ToastHelper";
import {useNavigate} from "react-router-dom";
import { IListarTermoResult } from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import { IDownloadTermoComprovanteCommand } from "interfaces/Commands/TermosCommands/IDownloadTermoComprovanteCommand";
import { TermosService } from "services/TermosService";
import { IAprovarEdicaoTermoCommand } from "interfaces/Commands/TermosCommands/IAprovarEdicaoTermoCommand";
import { IReprovarEdicaoTermoCommand } from "interfaces/Commands/TermosCommands/IReprovarEdicaoTermoCommand";
import { BaseHelper } from "helpers/BaseHelper";
import { FileHelper } from "helpers/FileHelper";

interface IProps {
	termo?: IListarTermoResult;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

export default function ModalTermoVisualizarComprovante(props: IProps) {
	const {
		termo,
		open,
		setOpen,
		setAtualizarLista
	} = props;
	const navigate = useNavigate();
	const tokenVisao = localStorage.getItem("tokenLogado");

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleDownloadComprovante = async (tokenTermo: string) => {
		const params: IDownloadTermoComprovanteCommand = {
			token: tokenTermo
		};

		try {
			const result = await TermosService.downloadComprovante(params, tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			ToastHelper("success", "Comprovante visualizado com sucesso.");
			FileHelper.show(body.data.base64, body.data.mimetype);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return;
		}
	};

	const handleAprovarEdicao = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IAprovarEdicaoTermoCommand = {
				token: termo?.token ?? ""
			};

			const result = await TermosService.aprovarEdicao(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				setDesabilitarBotao(false);
				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Edição aprovada com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	const handleReprovarEdicao = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IReprovarEdicaoTermoCommand = {
				token: termo?.token ?? ""
			};

			const result = await TermosService.reprovarEdicao(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				setDesabilitarBotao(false);
				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Edição reprovada com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>Permissão de Edição # {termo?.id}</DialogHeader>
			<DialogBody>
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					O Celebrante solicitou a permissão para editar este termo.
				</Typography>
				&emsp;
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					Segue abaixo o link de download do comprovante de transferência bancária realizada pelo celebrante:
				</Typography>
				&emsp;
				<Button fullWidth={true} className={"flex gap-3 items-center"} size={"sm"} variant={"gradient"} color={"blue"} onClick={() => handleDownloadComprovante(termo?.token ?? "")}>
					<HiOutlineDownload className={"w-4 h-4 ml-auto"}/>
					<Typography variant="small" className="font-normal  mr-auto">
						Baixar Comprovante
					</Typography>
				</Button>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefaultComponent color={"red"} description={"Reprovar"} onClick={handleReprovarEdicao} desabilitar={desabilitarBotao}/>
				<ButtonDefaultComponent color={"green"} description={"Aprovar"} onClick={handleAprovarEdicao} desabilitar={desabilitarBotao}/>
			</DialogFooter>
		</Dialog>
	);
}