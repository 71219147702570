import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

/* ESTILOS E ASSETS*/

/* COMPONENTS */
import {Breadcrumbs, Card, CardBody} from "@material-tailwind/react";
import Formulario from "../_components/formulario";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import EAcesso from "enums/EAcesso";
import EStrings from "enums/EStrings";
import { ToastHelper } from "helpers/ToastHelper";

export default function AdicionarUsuario() {
	const navigate = useNavigate();
	const acesso = localStorage.getItem("acesso");

	useEffect(() => {
		if (acesso != EAcesso.MASTER.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}
	}, [acesso]);

	return (
		<>
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Usuários </a>
					<a className="opacity-60"> Adicionar </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<Formulario textoCriarAtualizar={"Adicionar Usuário"} novo={true}/>
				</CardBody>
			</Card>
		</>
	);
}