import React from "react";
import {DataGrid, GridColDef, GridToolbar, ptBR} from "@mui/x-data-grid";

interface ITableComplement {
	columns: GridColDef[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rows: any[];
}

export default function TableComponent(props: ITableComplement) {
	const {columns, rows} = props;

	return (
		<DataGrid
			rows={rows}
			columns={columns}
			disableColumnFilter
			disableColumnSelector
			disableDensitySelector
			slots={{toolbar: GridToolbar}}
			slotProps={{
				toolbar: {
					showQuickFilter: true,
				},
			}}
			initialState={{
				pagination: {
					paginationModel: {page: 0, pageSize: 5},
				},
			}}
			localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
			pageSizeOptions={[5, 10, 15, 50]}
			className={"p-4 sm:col-span-12"}
			autoHeight
			rowHeight={75}
		/>
	);
}