import style from "./Image.module.scss";
import ModalImage from "react-modal-image";
import classNames from "classnames";

interface IProps {
	image: string;
}

export default function ImageComponent(props: IProps) {
	const {
		image
	} = props;

	return (
		<div className={classNames({
			["justify-center text-center w-full"]: true,
			[style.container]: true
		})}>
			<ModalImage small={image} large={image} hideZoom={true} className={style.image}/>
		</div>
	);
}