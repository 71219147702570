import ReactECharts from "echarts-for-react";
import {IBuscarChartResult} from "interfaces/CommandsResults/ChartsResults/IBuscarChartResult";
import React, {useEffect, useState} from "react";

interface IProps {
	titulo: string;
	data: IBuscarChartResult | null;
}

interface IChartProps {
	name: string;
	type: string;
	data: string[];
	smooth: boolean;
}

export default function LineChartComponent(props: IProps) {
	const {titulo, data} = props;

	const [series, setSeries] = useState<IChartProps[]>([]);

	useEffect(() => {
		if (data !== null && data !== undefined) {
			const series: IChartProps[] = data.data.map((item): IChartProps => ({
				name: item.titulo,
				type: "line",
				data: item.data,
				smooth: true
			}));

			setSeries(series);
		}
	}, [data]);

	const option = {
		title: {
			text: titulo,
			left: "center"
		},
		tooltip: {
			trigger: "axis"
		},
		legend: {
			bottom: 10
		},
		toolbox: {
			show: true,
			feature: {
				magicType: {type: ["line", "bar"]}
			}
		},
		xAxis: {
			type: "category",
			boundaryGap: false,
			data: data?.categoria.data
		},
		yAxis: {
			type: "value",
			axisLabel: {
				formatter: "{value}"
			}
		},
		series: series
	};

	return <ReactECharts option={option}/>;
}