import {INubenteTermoResult} from "interfaces/CommandsResults/NubentesResults/INubenteTermoResult";
import React, {Dispatch, SetStateAction} from "react";
import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";
import {Accordion, AccordionBody, AccordionHeader, Typography} from "@material-tailwind/react";
import styles from "../../NovoEditarTermo.module.scss";
import {IconComponent} from "components/IconComponent";
import {BaseHelper} from "helpers/BaseHelper";
import InputComponent from "components/InputComponent";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {DatePicker} from "@mui/x-date-pickers";

interface IProps {
	open: number;
	setOpen: Dispatch<SetStateAction<number>>;
	listaNubentes: INubenteTermoResult[];
	setListaNubentes: Dispatch<SetStateAction<INubenteTermoResult[]>>;
	listaAfirmacoes: IBuscarComboboxResult[];
}

export default function AccordionNubente(props: IProps) {
	const {open, setOpen, listaNubentes, setListaNubentes, listaAfirmacoes} = props;

	const handleOpenAccordionNubente = (value: number) => setOpen(open === value ? 0 : value);

	return (
		<>
			{
				listaNubentes.map((nubente, index) => (
					<Accordion key={index} open={open === index} className={styles.accordion} icon={IconComponent.arrow(index, open)} animate={BaseHelper.AnimationAccordion}>
						<AccordionHeader
							onClick={() => handleOpenAccordionNubente(index)}
							className={`border-b-0 transition-colors ${
								open === index ? "text-amber-500 hover:!text-amber-700" : ""
							}`}
						>
							{nubente.nome ?? ""}
						</AccordionHeader>
						<AccordionBody>
							<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
								<div className={"sm:col-span-12"}>
									<InputComponent
										type={"text"}
										label={"Naturalidade"}
										value={nubente.naturalidade}
										validation={nubente.naturalidade != ""}
										required={true}
										onChange={(e) => {
											const lista = [...listaNubentes];
											lista[index].naturalidade = e.target.value;
											setListaNubentes(lista);
										}}
									/>
								</div>

								<div className={"sm:col-span-12"}>
									<hr/>
								</div>

								<div className={"sm:col-span-8"}>
									<InputComponent
										type={"text"}
										label={"Nome Completo do Pai"}
										value={nubente.nome_pai}
										onChange={(e) => {
											const lista = [...listaNubentes];
											lista[index].nome_pai = e.target.value;
											setListaNubentes(lista);
										}}
									/>
								</div>

								<div className={"sm:col-span-2"}>
									<Autocomplete
										options={listaAfirmacoes}
										getOptionLabel={(option) => option.descricao}
										value={nubente.falecimento_pai_object}
										size={"small"}
										onChange={(_, newValue) => {
											const lista = [...listaNubentes];
											lista[index].falecimento_pai_object = newValue;
											setListaNubentes(lista);
										}}
										renderInput={(params) => <TextField {...params} label="É falecido?"/>}
									/>
								</div>

								<div className={"sm:col-span-2"}>
									<DatePicker
										label="Qual data?"
										format={"dd/MM/yyyy"}
										value={nubente.data_falecimento_pai_date}
										onChange={(e) => {
											const lista = [...listaNubentes];
											lista[index].data_falecimento_pai_date = e;
											setListaNubentes(lista);
										}}
										yearsPerRow={3}
										disableFuture={true}
										slotProps={{
											textField: {
												fullWidth: true,
												size: "small"
											}
										}}
									/>
								</div>

								<div className={"sm:col-span-12"}>
									<InputComponent
										type={"text"}
										label={"Endereço do Pai"}
										value={nubente.endereco_pai}
										onChange={(e) => {
											const lista = [...listaNubentes];
											lista[index].endereco_pai = e.target.value;
											setListaNubentes(lista);
										}}
									/>
								</div>

								<div className={"sm:col-span-12"}>
									<hr/>
								</div>

								<div className={"sm:col-span-8"}>
									<InputComponent
										type={"text"}
										label={"Nome Completo da Mãe"}
										value={nubente.nome_mae}
										required={true}
										onChange={(e) => {
											const lista = [...listaNubentes];
											lista[index].nome_mae = e.target.value;
											setListaNubentes(lista);
										}}
									/>
								</div>

								<div className={"sm:col-span-2"}>
									<Autocomplete
										options={listaAfirmacoes}
										getOptionLabel={(option) => option.descricao}
										value={nubente.falecimento_mae_object}
										size={"small"}
										onChange={(_, newValue) => {
											const lista = [...listaNubentes];
											lista[index].falecimento_mae_object = newValue;
											setListaNubentes(lista);
										}}
										renderInput={(params) => <TextField {...params} label="É falecida?" required={true}/>}
									/>
								</div>

								<div className={"sm:col-span-2"}>
									<DatePicker
										label="Qual data?"
										format={"dd/MM/yyyy"}
										value={nubente.data_falecimento_mae_date}
										onChange={(e) => {
											const lista = [...listaNubentes];
											lista[index].data_falecimento_mae_date = e;
											setListaNubentes(lista);
										}}
										yearsPerRow={3}
										disableFuture={true}
										slotProps={{
											textField: {
												fullWidth: true,
												size: "small"
											}
										}}
									/>
								</div>

								<div className={"sm:col-span-12"}>
									<InputComponent
										type={"text"}
										label={"Endereço da Mãe"}
										value={nubente.endereco_mae}
										onChange={(e) => {
											const lista = [...listaNubentes];
											lista[index].endereco_mae = e.target.value;
											setListaNubentes(lista);
										}}
									/>
								</div>

								<div className={"sm:col-span-12"}>
									<hr/>
								</div>

								<div className={"sm:col-span-12 space-y-2"}>
									<Typography color={"teal"} className={"font-monospace"}>
										Caso haja a alteração nos nomes, informe nos campos abaixo:
									</Typography>

									<InputComponent
										type={"text"}
										label={`De ${nubente.nome} para:`}
										value={nubente.nome_atualizacao}
										uppercase={true}
										onChange={(e) => {
											const lista = [...listaNubentes];
											lista[index].nome_atualizacao = e.target.value;
											setListaNubentes(lista);
										}}
									/>
								</div>
							</div>
						</AccordionBody>
					</Accordion>
				))
			}
		</>
	);
}