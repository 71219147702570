import {Button, ButtonGroup, Card, CardBody, Dialog, DialogBody, DialogFooter, DialogHeader, IconButton, List, ListItem, ListItemSuffix, Textarea, Typography} from "@material-tailwind/react";
import {HiOutlineArrowDownTray, HiOutlineArrowTopRightOnSquare, HiOutlineTrash} from "react-icons/hi2";
import {BaseHelper} from "helpers/BaseHelper";
import InputDropDragPdfComponent from "components/InputDropDragComponents/PdfComponent";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import FormularioNovoEditar from "../../../_components/formularios/novoEditar";
import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import {IBuscarPdfTermoCommand} from "interfaces/Commands/TermosCommands/IBuscarPdfTermoCommand";
import {TermosService} from "services/TermosService";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import ERotas from "enums/ERotas";
import {PdfHelper} from "helpers/PdfHelper";
import {IAprovarTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarTermoCommand";
import {IReprovarTermoCommand} from "interfaces/Commands/TermosCommands/IReprovarTermoCommand";
import {useNavigate} from "react-router-dom";
import {IBuscarTermoResult} from "interfaces/CommandsResults/TermosResults/IBuscarTermoResult";
import {IBuscarModeloPdfTermoCommand} from "../../../../../interfaces/Commands/TermosCommands/IBuscarModeloPdfTermoCommand";
import EAcesso from "../../../../../enums/EAcesso";

interface IProps {
	data: IBuscarTermoResult | undefined;
	token: string;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export function TermoFormulario(props: IProps) {
	const navigate = useNavigate();
	const {data, token, setIsLoading} = props;

	const acesso = localStorage.getItem("acesso");
	const tokenVisao = localStorage.getItem("tokenLogado");

	const [openDialogAprova, setOpenDialogAprova] = useState<boolean>(false);
	const [openDialogReprova, setOpenDialogReprova] = useState<boolean>(false);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const [motivoReprovacao, setMotivoReprovacao] = useState<string>("");

	const [files, setFiles] = useState<File[]>([]);

	const handlePdfChange = async () => {
		setIsLoading(true);

		const params: IBuscarPdfTermoCommand = {
			token: token ?? ""
		};

		try {
			const result = await TermosService.buscarPdf(params, tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoading(false);
					return navigate(ERotas.LOGIN);
				}

				setIsLoading(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoading(false);
				return navigate(-1);
			}

			PdfHelper.showPdf(body.data.base64);
			ToastHelper("success", "PDF gerado com sucesso.");

			setIsLoading(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoading(false);
			return navigate(-1);
		}
	};

	const handleOpenDialogAprova = () => {
		setFiles([]);
		setOpenDialogAprova(!openDialogAprova);
	};

	const handleOpenDialogReprova = () => {
		setMotivoReprovacao("");
		setOpenDialogReprova(!openDialogReprova);
	};

	const handleExcluirFile = (indexToRemove: number) => {
		setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
	};

	const handleOpenModeloChange = async () => {
		setIsLoading(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IBuscarModeloPdfTermoCommand = {
				token: data?.token ?? ""
			};

			const result = await TermosService.buscarModeloPdf(params, tokenVisao);

			if (!result) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					setIsLoading(false);
					return;
				}

				setIsLoading(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setIsLoading(false);
			PdfHelper.showPdf(body.data.base64);
		} catch (error) {
			setIsLoading(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	const handleEnviarAprova = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const filesBase64: string[] = [];

			for (const file of files) {
				const base64String = await BaseHelper.FileToBase64(file);

				filesBase64.push(base64String);
			}

			const params: IAprovarTermoCommand = {
				token: token ?? "",
				files: filesBase64
			};

			const result = await TermosService.aprovar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoading(false);
					return navigate(ERotas.LOGIN);
				}

				setIsLoading(false);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpenDialogAprova(false);

			ToastHelper("success", "Termo aprovado com sucesso.");

			setTimeout(() => {
				navigate(ERotas.TERMO_LISTAR_PENDENTES);
			}, EStrings.TIMEOUT);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoading(false);
			setDesabilitarBotao(false);
			setOpenDialogAprova(false);

			return;
		}
	};

	const handleEnviarReprova = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IReprovarTermoCommand = {
				token: token ?? "",
				motivo_reprovacao: motivoReprovacao
			};

			const result = await TermosService.reprovar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoading(false);
					return navigate(ERotas.LOGIN);
				}

				setIsLoading(false);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpenDialogAprova(false);

			ToastHelper("success", "Termo reprovado com sucesso.");

			setTimeout(() => {
				navigate(ERotas.TERMO_LISTAR_PENDENTES);
			}, EStrings.TIMEOUT);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoading(false);
			setDesabilitarBotao(false);
			setOpenDialogAprova(false);

			return;
		}
	};

	return (
		<>
			<Card className={"w-full"}>
				<CardBody className={"w-full m-0 p-0 space-y-2"}>
					<div className="card-body p-5">
						<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
							<div className={"sm:col-span-9"}>
								<Button
									variant="text"
									color={"light-blue"}
									size={"md"}
									className="rounded-full flex items-center gap-3"
									onClick={handlePdfChange}
								>
									<HiOutlineArrowDownTray className={"h-5 w-5"}/>
									Baixar arquivo para assinar
								</Button>
							</div>

							<div className={"sm:col-span-3"}>
								<ButtonGroup size={"md"} variant={"gradient"} color={"white"} fullWidth={true}>
									<Button className="bg-green-600 text-white hover:bg-green-800" onClick={handleOpenDialogAprova}>Aprovar</Button>
									<Button className="bg-red-600 text-white hover:bg-red-800" onClick={handleOpenDialogReprova}>Reprovar</Button>
								</ButtonGroup>
							</div>
						</div>
					</div>

					<Dialog open={openDialogAprova} handler={handleOpenDialogAprova} size={"md"} animate={BaseHelper.AnimationDialog}>
						<DialogHeader>Deseja aprovar o termo?</DialogHeader>
						<DialogBody>
							<Typography variant={"lead"} color={"red"}>
								Obs.: Esta ação não poderá ser desfeita.
							</Typography>
							<form id={"formAprovar"} onSubmit={handleEnviarAprova} noValidate>
								<InputDropDragPdfComponent setFiles={setFiles} multiple={true}/>
							</form>
							<List>
								{files.map((file, index) => (
									<ListItem key={index} ripple={false} className={"py-1 pr-1 pl-4"}>
										{file.name} - {BaseHelper.GetSizeFile(file.size)}
										<ListItemSuffix>
											<IconButton variant={"text"} color={"blue-gray"} onClick={() => handleExcluirFile(index)}>
												<HiOutlineTrash className={"w-5 h-5"}/>
											</IconButton>
										</ListItemSuffix>
									</ListItem>
								))}
							</List>
						</DialogBody>
						<DialogFooter className={"space-x-2"}>
							<ButtonDefaultComponent color={"red"} description={"Fechar"} onClick={() => setOpenDialogAprova(false)} desabilitar={desabilitarBotao}/>
							<ButtonSubmitComponent color={"green"} description={"Confirmar"} form={"formAprovar"} desabilitar={desabilitarBotao}/>
						</DialogFooter>
					</Dialog>

					<Dialog open={openDialogReprova} handler={() => setOpenDialogReprova(!openDialogReprova)} size={"sm"} animate={BaseHelper.AnimationDialog}>
						<DialogHeader>Deseja reprovar o termo?</DialogHeader>
						<DialogBody>
							<Typography variant={"small"} className={"font-monospace text-justify"}>
								Digite abaixo o motivo da reprovação.
							</Typography>
							<Typography variant={"small"} className={"font-monospace text-justify"} color={"red"}>
								Obs.: Esta ação não poderá ser desfeita.
							</Typography>
							&emsp;
							<form id={"formReprovar"} onSubmit={handleEnviarReprova} noValidate>
								<Textarea
									color={"blue"}
									label={"Motivo da Reprovação"}
									value={motivoReprovacao}
									variant={"outlined"}
									size={"md"}
									required={true}
									onChange={(e) => setMotivoReprovacao(e.target.value)}
								/>
							</form>
						</DialogBody>
						<DialogFooter className={"space-x-2"}>
							<ButtonDefaultComponent color={"green"} description={"Fechar"} onClick={() => setOpenDialogReprova(!openDialogReprova)} desabilitar={desabilitarBotao}/>
							<ButtonSubmitComponent form={"formReprovar"} description={"Confirmar"} color={"red"} desabilitar={desabilitarBotao}/>
						</DialogFooter>
					</Dialog>
				</CardBody>
			</Card>

			{acesso === EAcesso.MASTER.toString() &&
				<div className={"sm:col-span-12 flex justify-center"}>
					<Button
						variant="gradient"
						color={"light-blue"}
						size={"sm"}
						className="rounded-full flex items-center gap-3"
						onClick={handleOpenModeloChange}
					>
						<HiOutlineArrowTopRightOnSquare className={"h-5 w-5"}/>
						Visualize seu Modelo
					</Button>
				</div>
			}

			<FormularioNovoEditar data={data} textoCriarAtualizar={"Atualizar"} novo={false}/>
		</>
	);
}