import {ParametrosService} from "services/ParametrosService";
import {ToastHelper} from "./ToastHelper";
import EStrings from "../enums/EStrings";
import {LogErrorHelper} from "./LogErrorHelper";

const buscarCep = async (cep: string) => {
	try {
		const result = await ParametrosService.buscarCep(cep);

		if (!result) {
			return null;
		}

		const errors = result.data.errors;

		if (errors.length > 0) {
			const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

			if (error !== undefined) {
				LogErrorHelper.redirectToLogin("warning", errors[0].message);

				return null;
			}

			ToastHelper("warning", errors[0].message);

			return null;
		}

		const body = result.data;

		if (!body) {
			return null;
		}

		return body.data;
	} catch (error) {
		return null;
	}
};

const formatarCep = (cep: string) => {
	// Remover caracteres não numéricos
	cep = cep.replace(/\D/g, "");

	// Formatá-lo na estrutura desejada
	return cep.replace(/^(\d{5})(\d{3})$/, "$1-$2");
};

export const CepHelper = {
	buscarCep,
	formatarCep
};