import {Api} from "providers/Api";
import {ApiResult} from "interfaces/ApiResult";
import {IBuscarChartResult} from "interfaces/CommandsResults/ChartsResults/IBuscarChartResult";

const controller = "/charts";

const buscar = (token: string) => Api.post<ApiResult<IBuscarChartResult>>(`${controller}/buscar`, {}, {
	headers: {
		token: token
	}
});

export const ChartsService = {
	buscar
};