import SpinnerComponent from "components/SpinnerComponent";
import {Alert, Breadcrumbs, Card, CardBody, Typography} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {IBuscarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IBuscarRequerimentoResult";
import FormularioNovoEditar from "../_components/formularios/novoEditar";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {IBuscarRequerimentoCommand} from "interfaces/Commands/RequerimentosCommands/IBuscarRequerimentoCommand";
import {RequerimentosService} from "services/RequerimentosService";
import EAcesso from "enums/EAcesso";
import {IconComponent} from "components/IconComponent";
import EStatusRequerimento from "enums/EStatusRequerimento";
import {ToastHelper} from "helpers/ToastHelper";
import {TimelineRequerimentoComponent} from "../_components/timelineComponent";

export default function EditarRequerimento() {
	const navigate = useNavigate();
	const {token} = useParams();
	const tokenVisao = localStorage.getItem("tokenLogado");
	const acesso = localStorage.getItem("acesso");

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(true);
	const [data, setData] = useState<IBuscarRequerimentoResult>();

	const [openTimeline, setOpenTimeline] = useState<boolean>(false);

	useEffect(() => {
		buscarDados();
	}, []);

	useEffect(() => {
		if (acesso != EAcesso.CELEBRANTE.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}
	}, [acesso]);

	const buscarDados = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		if (!token || token === "") {
			ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IBuscarRequerimentoCommand = {
				token: token
			};

			const result = await RequerimentosService.buscar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setIsLoadingIndex(false);
				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoadingIndex(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			if (body.data.status !== EStatusRequerimento.PENDENTE && body.data.status !== EStatusRequerimento.AGUARDANDO) {
				ToastHelper("warning", EStrings.REQUERIMENTO_NAO_AUTENTICADO_ACAO);

				navigate(-1);

				return;
			}

			setData(body.data);
			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoadingIndex(false);
			return navigate(ERotas.PRINCIPAL);
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Requerimentos </a>
					<a className="opacity-60"> Editar </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					{!isLoadingIndex &&
						<>
							{data?.motivo_reprova !== "" && data?.motivo_reprova !== undefined && data?.motivo_reprova !== null &&
								<Alert color="green" icon={IconComponent.alert()}>
									<Typography className={"font-medium"}>
										Segue abaixo o motivo da reprovação para correção:
									</Typography>
									<ul className={"mt-2 ml-2 list-inside list-disc"}>
										<li>{data?.motivo_reprova}</li>
									</ul>
								</Alert>
							}

							<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
								<div className={"sm:col-span-12 flex justify-center"}>
									<ButtonDefaultComponent
										color={"teal"}
										onClick={() => setOpenTimeline(!openTimeline)}
										description={"Clique aqui para acessar a Timeline do Requerimento"}
										variant={"gradient"}
									/>
								</div>
							</div>

							<FormularioNovoEditar data={data} textoCriarAtualizar={"Atualizar Requerimento"} novo={false}/>
						</>
					}
				</CardBody>
			</Card>
			<TimelineRequerimentoComponent
				requerimento={data?.token ?? ""}
				nomePrimeiroNubente={data?.nubentes[0].nome ?? ""}
				nomeSegundoNubente={data?.nubentes[1].nome ?? ""}
				open={openTimeline}
				setOpen={setOpenTimeline}
			/>
		</>
	);
}