const show = (base64: string, mimetype: string) => {
	const byteCharacters = atob(base64);

	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);

	// Cria um blob a partir do array de buffer
	const blob = new Blob([byteArray], {type: mimetype});

	// Cria uma URL para o blob
	const fileUrl = URL.createObjectURL(blob);

	// Abre uma nova guia com o PDF
	window.open(fileUrl, "_blank");
};

const download = (base64: string, fileName: string) => {
	let file: string = base64;

	if (base64.startsWith("data:")) {
		// Divida a string na vírgula e pegue a segunda parte (representação Base64 real)
		const parts = base64.split(",");

		if (parts.length === 2) {
			file = parts[1];
		}
	}

	// Converte o conteúdo em base64 para um Blob
	const byteCharacters = atob(file);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], {type: getMimeType(base64)});

	// Cria uma URL temporária para o Blob
	const url = window.URL.createObjectURL(blob);

	// Cria um elemento âncora para o download
	const a = document.createElement("a");
	a.href = url;
	a.download = fileName;

	// Dispara um evento de clique no elemento âncora
	a.click();

	// Libera a URL temporária
	window.URL.revokeObjectURL(url);
};

const getMimeType = (base64: string) => {
	// Divida a string usando "," como separador
	const parts = base64.split(",");

	// Verifique se a primeira parte começa com "data:" e contém o tipo MIME
	if (parts[0].startsWith("data:") && parts[0].includes(";base64")) {
		// Extrai o tipo MIME da primeira parte
		return parts[0].split(";")[0].substring(5);
	}

	// Se não corresponder a nenhum tipo conhecido, retorne um valor padrão
	return "application/octet-stream"; // Tipo MIME genérico para binário
};

export const FileHelper = {
	show,
	download
};