import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {BiShieldAlt2} from "react-icons/bi";
import {Drawer, List, ListItem, ListItemPrefix, Typography, Accordion, AccordionHeader, AccordionBody} from "@material-tailwind/react";

import logo from "assets/logo.jpg";
import styles from "./MenuComponent.module.scss";
import ERotas from "enums/ERotas";
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import {HiOutlineArrowUturnLeft, HiOutlineBuildingLibrary, HiOutlineBuildingOffice, HiOutlineBuildingOffice2, HiOutlineCheckBadge, HiOutlineCog, HiOutlineCurrencyDollar, HiOutlineDocument, HiOutlineDocumentCheck, HiOutlineDocumentMagnifyingGlass, HiOutlineDocumentMinus, HiOutlineDocumentText, HiOutlineHome, HiOutlinePlus, HiOutlineSquare3Stack3D, HiOutlineUserGroup, HiOutlineXMark} from "react-icons/hi2";
import EAcesso from "enums/EAcesso";
import {Divider} from "@mui/material";
import {HiOutlineDocumentReport} from "react-icons/hi";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import {ParametrosService} from "services/ParametrosService";
import {LogErrorHelper} from "../../helpers/LogErrorHelper";
import SpinnerComponent from "components/SpinnerComponent";

interface IProps {
	turnOn: boolean;
	setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MenuComponent(props: IProps) {
	const navigate = useNavigate();

	const [openItem, setOpenItem] = useState<number>(0);
	const acesso = localStorage.getItem("acesso");
	const ceo = localStorage.getItem("ceo");
	const tokenVisao = localStorage.getItem("tokenLogado");
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleOpen = (value: number) => {
		setOpenItem(openItem === value ? 0 : value);
	};

	const handleAcessarPagina = (rota: string) => {
		props.setOpenMenu(false);
		navigate(rota);
	};

	const handleTermoUso = async () => {
		setIsLoading(true);

		try {
			const result = await ParametrosService.buscarTermosUso(tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoading(false);
					return;
				}

				ToastHelper("warning", errors[0].message);
				setIsLoading(false);
				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoading(false);
				return;
			}

			window.open(body.data.url, "_blank");
			setIsLoading(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setIsLoading(false);
				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setIsLoading(false);
			return;
		}
	};

	return (
		<>
			{isLoading &&
				<SpinnerComponent/>
			}

			<React.Fragment>
				<Drawer open={props.turnOn} onClose={() => props.setOpenMenu(false)} className="h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 overflow-y-auto">
					<div className={styles.div}>
						<img src={logo} alt={"Logo da Escola de Celebrantes"} className={styles.div__imagem}/>
					</div>
					<hr/>
					<List>
						<ListItem onClick={() => handleAcessarPagina(ERotas.PRINCIPAL)}>
							<ListItemPrefix className="h-5 w-5"><HiOutlineHome/></ListItemPrefix>
							<Typography color="blue-gray" className="mr-auto font-monospace">
								Página Inicial
							</Typography>
						</ListItem>
						{acesso == EAcesso.MASTER.toString() &&
							<Accordion
								open={openItem === 1}
								icon={
									<ChevronDownIcon
										strokeWidth={2.5}
										className={`mx-auto h-4 w-4 transition-transform ${openItem === 1 ? "rotate-180" : ""}`}
									/>
								}>
								<ListItem className="p-0" selected={openItem === 1}>
									<AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineCog/></ListItemPrefix>
										<Typography color="blue-gray" className="mr-auto font-monospace">
											Administração
										</Typography>
									</AccordionHeader>
								</ListItem>
								<AccordionBody className="py-1">
									<List className="p-0">
										<ListItem onClick={() => handleAcessarPagina(ERotas.CARTORIOS_LISTAR)}>
											<ListItemPrefix className="h-5 w-5"><HiOutlineBuildingLibrary/></ListItemPrefix>
											<Typography color="blue-gray" className="mr-auto font-monospace">
												Cartórios
											</Typography>
										</ListItem>

										{ceo &&
											<ListItem onClick={() => handleAcessarPagina(ERotas.DADOS_BANCARIOS_LISTAR)}>
												<ListItemPrefix className={"h-5 w-5"}><HiOutlineCurrencyDollar/></ListItemPrefix>
												<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
													Dados Bancários
												</Typography>
											</ListItem>
										}

										<ListItem onClick={() => handleAcessarPagina(ERotas.USUARIO_LISTAR)}>
											<ListItemPrefix className={"h-5 w-5"}><HiOutlineUserGroup/></ListItemPrefix>
											<Typography color="blue-gray" className="mr-auto font-monospace">
												Usuários
											</Typography>
										</ListItem>
									</List>
								</AccordionBody>
							</Accordion>
						}
						<Accordion
							open={openItem === 2}
							icon={
								<ChevronDownIcon
									strokeWidth={2.5}
									className={`mx-auto h-4 w-4 transition-transform ${openItem === 2 ? "rotate-180" : ""}`}
								/>
							}>
							<ListItem className={"p-0"} selected={openItem === 2}>
								<AccordionHeader onClick={() => handleOpen(2)} className={"border-b-0 p-3"}>
									<ListItemPrefix className={"h-5 w-5"}><HiOutlineBuildingOffice/></ListItemPrefix>
									<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
										Requerimentos
									</Typography>
								</AccordionHeader>
							</ListItem>
							<AccordionBody className={"py-1"}>
								<List className={"p-0"}>
									{acesso == EAcesso.CELEBRANTE.toString() &&
										<>
											<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_ADICIONAR)}>
												<ListItemPrefix className={"h-5 w-5"}><HiOutlinePlus/></ListItemPrefix>
												<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
													Novo
												</Typography>
											</ListItem>
										</>
									}

									<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_LISTAR_TODOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineSquare3Stack3D/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Todos
										</Typography>
									</ListItem>

									{acesso == EAcesso.MASTER.toString() &&
										<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_LISTAR_ROLLBACK)}>
											<ListItemPrefix className={"h-5 w-5"}><HiOutlineArrowUturnLeft/></ListItemPrefix>
											<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
												Rollback
											</Typography>
										</ListItem>
									}

									<Divider variant="middle"/>

									<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_LISTAR_AGUARDANDO)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentText/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											{acesso == EAcesso.MASTER.toString() ? "Aguardando Conf. de Pagamento" : "Faça sua Edição"}
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_LISTAR_APROVADOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentCheck/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Aprovados
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_LISTAR_CANCELADOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineXMark/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Cancelados
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_LISTAR_FINALIZADOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineCheckBadge/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Finalizados
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_LISTAR_PENDENTES)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocument/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Pendentes
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_LISTAR_REPROVADOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentMinus/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Reprovados
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.REQUERIMENTO_LISTAR_SOLICITACAO_CANCELAMENTO)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentReport/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Solicitação de Cancelamento
										</Typography>
									</ListItem>
								</List>
							</AccordionBody>
						</Accordion>
						<Accordion
							open={openItem === 3}
							icon={
								<ChevronDownIcon
									strokeWidth={2.5}
									className={`mx-auto h-4 w-4 transition-transform ${openItem === 3 ? "rotate-180" : ""}`}
								/>
							}>
							<ListItem className={"p-0"} selected={openItem === 3}>
								<AccordionHeader onClick={() => handleOpen(3)} className={"border-b-0 p-3"}>
									<ListItemPrefix className={"h-5 w-5"}><HiOutlineBuildingOffice2/></ListItemPrefix>
									<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
										Termos
									</Typography>
								</AccordionHeader>
							</ListItem>
							<AccordionBody className={"py-1"}>
								<List className={"p-0"}>
									{acesso == EAcesso.CELEBRANTE.toString() &&
										<>
											<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_ADICIONAR)}>
												<ListItemPrefix className={"h-5 w-5"}><HiOutlinePlus/></ListItemPrefix>
												<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
													Novo
												</Typography>
											</ListItem>
										</>
									}

									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_TODOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineSquare3Stack3D/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Todos
										</Typography>
									</ListItem>

									{acesso == EAcesso.MASTER.toString() &&
										<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_ROLLBACK)}>
											<ListItemPrefix className={"h-5 w-5"}><HiOutlineArrowUturnLeft/></ListItemPrefix>
											<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
												Rollback
											</Typography>
										</ListItem>
									}
									<Divider variant="middle"/>

									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_AGUARDANDO)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentText/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											{acesso == EAcesso.MASTER.toString() ? "Aguardando Conf. de Pagamento" : "Faça sua Edição"}
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_APROVADOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentCheck/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Aprovados
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_CANCELADOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineXMark/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Cancelados
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_FINALIZADOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineCheckBadge/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Finalizados
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_PENDENTES)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocument/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Pendentes
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_REPROVADOS)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentMinus/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Reprovados
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_REPROVADOS_FINALIZACAO)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentMinus/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Reprovados de Finalização
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_SOLICITACAO_CANCELAMENTO)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentReport/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Solicitação de Cancelamento
										</Typography>
									</ListItem>
									<ListItem onClick={() => handleAcessarPagina(ERotas.TERMO_LISTAR_SOLICITACAO_FINALIZACAO)}>
										<ListItemPrefix className={"h-5 w-5"}><HiOutlineDocumentMagnifyingGlass/></ListItemPrefix>
										<Typography color={"blue-gray"} className={"mr-auto font-monospace"}>
											Solicitação de Finalização
										</Typography>
									</ListItem>
								</List>
							</AccordionBody>
						</Accordion>
						<hr className="my-2 border-blue-gray-100"/>
					</List>
					<List>
						<ListItem onClick={() => handleTermoUso()}>
							<ListItemPrefix><BiShieldAlt2/></ListItemPrefix>
							Termos de Uso
						</ListItem>
					</List>
				</Drawer>
			</React.Fragment>
		</>
	);
}