import TabelaDocumentos from "../../../_components/tabelas/listarDocumentos";
import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {IListarDocumentosTermoResult} from "interfaces/CommandsResults/DocumentosResults/IListarDocumentosTermoResult";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {IListarDocumentosTermoCommand} from "interfaces/Commands/DocumentosCommands/IListarDocumentosTermoCommand";
import {DocumentosService} from "services/DocumentosService";
import {ToastHelper} from "helpers/ToastHelper";
import {useNavigate} from "react-router-dom";
import {Alert, Button, ButtonGroup, Card, CardBody, Dialog, DialogBody, DialogFooter, DialogHeader, IconButton, List, ListItem, ListItemSuffix, Textarea, Typography} from "@material-tailwind/react";
import {HiOutlineExclamation} from "react-icons/hi";
import {BaseHelper} from "helpers/BaseHelper";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import {IReprovarTermoCommand} from "interfaces/Commands/TermosCommands/IReprovarTermoCommand";
import {TermosService} from "services/TermosService";
import InputDropDragPdfComponent from "components/InputDropDragComponents/PdfComponent";
import {IAprovarTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarTermoCommand";
import {HiOutlineTrash} from "react-icons/hi2";

interface IProps {
	token: string;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export function TermoSemAssinatura(props: IProps) {
	const navigate = useNavigate();
	const {token, setIsLoading} = props;
	const tokenVisao = localStorage.getItem("tokenLogado");

	const [listaDocumentos, setListaDocumentos] = useState<IListarDocumentosTermoResult[]>([]);
	const [atualizarListaDocumentos, setAtualizarListaDocumentos] = useState<boolean>(false);

	const [openDialogAprova, setOpenDialogAprova] = useState<boolean>(false);
	const [openDialogReprova, setOpenDialogReprova] = useState<boolean>(false);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const [motivoReprovacao, setMotivoReprovacao] = useState<string>("");

	const [files, setFiles] = useState<File[]>([]);

	useEffect(() => {
		listarDocumentos(token);
	}, [token]);

	const listarDocumentos = async (termo: string) => {
		setIsLoading(true);
		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			setIsLoading(false);

			const params: IListarDocumentosTermoCommand = {
				token: termo
			};

			const result = await DocumentosService.listarDocumentosTermo(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setListaDocumentos(body.data);
		} catch (error) {
			setIsLoading(true);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	const handleOpenDialogAprova = () => {
		setOpenDialogAprova(!openDialogAprova);
	};

	const handleOpenDialogReprova = () => {
		setMotivoReprovacao("");
		setOpenDialogReprova(!openDialogReprova);
	};

	const handleExcluirFile = (indexToRemove: number) => {
		setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
	};

	const handleEnviarAprova = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const filesBase64: string[] = [];

			for (const file of files) {
				const base64String = await BaseHelper.FileToBase64(file);

				filesBase64.push(base64String);
			}

			const params: IAprovarTermoCommand = {
				token: token ?? "",
				files: filesBase64
			};

			const result = await TermosService.aprovar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoading(false);
					return navigate(ERotas.LOGIN);
				}

				setIsLoading(false);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpenDialogAprova(false);

			ToastHelper("success", "Termo aprovado com sucesso.");

			setTimeout(() => {
				navigate(ERotas.TERMO_LISTAR_PENDENTES);
			}, EStrings.TIMEOUT);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoading(false);
			setDesabilitarBotao(false);
			setOpenDialogAprova(false);

			return;
		}
	};

	const handleEnviarReprova = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IReprovarTermoCommand = {
				token: token ?? "",
				motivo_reprovacao: motivoReprovacao
			};

			const result = await TermosService.reprovar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoading(false);
					return navigate(ERotas.LOGIN);
				}

				setIsLoading(false);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpenDialogAprova(false);

			ToastHelper("success", "Termo reprovado com sucesso.");

			setTimeout(() => {
				navigate(ERotas.TERMO_LISTAR_PENDENTES);
			}, EStrings.TIMEOUT);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoading(false);
			setDesabilitarBotao(false);
			setOpenDialogAprova(false);

			return;
		}
	};

	return (
		<>
			<Card className={"w-full"}>
				<CardBody className={"w-full m-0 p-0 space-y-2"}>
					<Alert color={"teal"} className={"w-full space-x-2 text-center flex items-center justify-center"} variant={"gradient"} icon={<HiOutlineExclamation/>}>
						<Typography className="font-medium">
							Aprovação de Termo
						</Typography>
					</Alert>
					<div className="card-body p-5">
						<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
							<div className={"sm:col-span-9"}>
								Visualize os documentos enviados e realize a ação necessária!
							</div>

							<div className={"sm:col-span-3"}>
								<ButtonGroup size={"md"} variant={"gradient"} color={"white"} fullWidth={true}>
									<Button className="bg-green-600 text-white hover:bg-green-800" onClick={handleOpenDialogAprova}>Aprovar</Button>
									<Button className="bg-red-600 text-white hover:bg-red-800" onClick={handleOpenDialogReprova}>Reprovar</Button>
								</ButtonGroup>
							</div>
						</div>
					</div>

					<Dialog open={openDialogAprova} handler={handleOpenDialogAprova} size={"md"} animate={BaseHelper.AnimationDialog}>
						<DialogHeader>Deseja aprovar o termo?</DialogHeader>
						<DialogBody>
							<Typography variant={"lead"} color={"red"}>
								Obs.: Esta ação não poderá ser desfeita.
							</Typography>
							<form id={"formAprovar"} onSubmit={handleEnviarAprova} noValidate>
								<InputDropDragPdfComponent setFiles={setFiles} multiple={true}/>
							</form>
							<List>
								{files.map((file, index) => (
									<ListItem key={index} ripple={false} className={"py-1 pr-1 pl-4"}>
										{file.name} - {BaseHelper.GetSizeFile(file.size)}
										<ListItemSuffix>
											<IconButton variant={"text"} color={"blue-gray"} onClick={() => handleExcluirFile(index)}>
												<HiOutlineTrash className={"w-5 h-5"}/>
											</IconButton>
										</ListItemSuffix>
									</ListItem>
								))}
							</List>
						</DialogBody>
						<DialogFooter className={"space-x-2"}>
							<ButtonDefaultComponent color={"red"} description={"Fechar"} onClick={() => setOpenDialogAprova(false)} desabilitar={desabilitarBotao}/>
							<ButtonSubmitComponent color={"green"} description={"Confirmar"} form={"formAprovar"} desabilitar={desabilitarBotao}/>
						</DialogFooter>
					</Dialog>

					<Dialog open={openDialogReprova} handler={() => setOpenDialogReprova(!openDialogReprova)} size={"sm"} animate={BaseHelper.AnimationDialog}>
						<DialogHeader>Deseja reprovar o termo?</DialogHeader>
						<DialogBody>
							<Typography variant={"small"} className={"font-monospace text-justify"}>
								Digite abaixo o motivo da reprovação.
							</Typography>
							<Typography variant={"small"} className={"font-monospace text-justify"} color={"red"}>
								Obs.: Esta ação não poderá ser desfeita.
							</Typography>
							&emsp;
							<form id={"formReprovar"} onSubmit={handleEnviarReprova} noValidate>
								<Textarea
									color={"blue"}
									label={"Motivo da Reprovação"}
									value={motivoReprovacao}
									variant={"outlined"}
									size={"md"}
									required={true}
									onChange={(e) => setMotivoReprovacao(e.target.value)}
								/>
							</form>
						</DialogBody>
						<DialogFooter className={"space-x-2"}>
							<ButtonDefaultComponent color={"green"} description={"Fechar"} onClick={() => setOpenDialogReprova(!openDialogReprova)} desabilitar={desabilitarBotao}/>
							<ButtonSubmitComponent form={"formReprovar"} description={"Confirmar"} color={"red"} desabilitar={desabilitarBotao}/>
						</DialogFooter>
					</Dialog>
				</CardBody>
			</Card>

			<div className={"sm:col-span-12"}>
				<legend className={"pb-3"}>Documentos Anexados</legend>
			</div>
			<TabelaDocumentos data={listaDocumentos} setData={setListaDocumentos} setIsLoading={setIsLoading} setAtualizarListaDocumentos={setAtualizarListaDocumentos}/>
		</>
	);
}