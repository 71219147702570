import styles from "./NotFoundLayout.module.scss";
import {Card, CardBody, Typography} from "@material-tailwind/react";
import logo from "assets/logo.jpg";
import ToastContainerComponent from "components/ToastContainerComponent";
import React from "react";
import classNames from "classnames";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {useNavigate} from "react-router-dom";

export default function NotFound() {
	const navigate = useNavigate();
	return (
		<main className={styles.container}>
			<Card shadow={true} className={classNames({
				[styles.container__card]: true,
				"space-y-8": true
			})}>
				<img src={logo} alt={"Logo da Escola de Celebrantes"} className={styles.container__card__imagem}/>
				<CardBody className={styles.container__card__body}>
					<Typography color={"blue-gray"} className={"mr-auto ml-auto font-monospace"}>
						Página não encontrada!
					</Typography>

					<ButtonDefaultComponent color={"amber"} description={"Voltar"} onClick={() => navigate(-1)}/>
				</CardBody>
			</Card>
			<ToastContainerComponent/>
		</main>
	);
}