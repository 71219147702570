import {toast, ToastOptions} from "react-toastify";

export function ToastHelper(tipo: string, mensagem: string) {
	function removerCaracteresEspeciais(mensagem: string) {
		// Use uma expressão regular para remover caracteres especiais e espaços
		return mensagem.replace(/[^\w\s]/g, "");
	}

	const toastOptions: ToastOptions = {
		toastId: removerCaracteresEspeciais(mensagem),
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		theme: "light",
		style: {
			zIndex: 99999, // Escolha o valor de z-index desejado
		}
	};

	switch (tipo) {
	case "success":
		toast.success(mensagem, toastOptions);
		break;
	case "warning":
		toast.warning(mensagem, toastOptions);

		break;
	case "error":
		toast.error(mensagem, toastOptions);

		break;
	case "":
		toast(mensagem, toastOptions);
		break;
	default:
		toast(mensagem, toastOptions);

		break;
	}
}
